import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { WebHostingComponent } from './web-hosting/web-hosting.component';
import { ResellerComponent } from './reseller/reseller.component';
import { PymeComponent } from './pyme/pyme.component';
import { VpsComponent } from './vps/vps.component';
import { VpsBackupComponent } from './vps-backup/vps-backup.component';
import { SponsorshipProgramComponent } from './sponsorship-program/sponsorship-program.component';
import { PartnersProgramComponent } from './partners-program/partners-program.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { LogoCenterComponent } from './logo-center/logo-center.component';
import { ContactComponent } from './contact/contact.component';
import { DomainComponent } from './domain/domain.component';
import { PromoComponent } from './promo/promo.component';
import { SuccessComponent } from './success/success.component';
import { SuccessGuard } from './success/success.guard';
import { DirectPaymentComponent } from './direct-payment/direct-payment.component';


const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
    }, {
        path: 'web-hosting/.',
        component: WebHostingComponent
    }, {
        path: 'web-hosting',
        redirectTo: 'web-hosting/.',
        pathMatch: 'full'
    }, {
        path: 'web-hosting-reseller/.',
        component: ResellerComponent
    },  {
        path: 'success',
        component: SuccessComponent,
        canActivate: [SuccessGuard],
    }, {
        path: 'web-hosting-reseller',
        redirectTo: 'web-hosting-reseller/.',
        pathMatch: 'full'
    }, {
        path: 'web-hosting-pyme/.',
        component: PymeComponent
    }, {
        path: 'web-hosting-pyme',
        redirectTo: 'web-hosting-pyme/.',
        pathMatch: 'full'
    }, {
        path: 'cloud-vps/.',
        component: VpsComponent
    }, {
        path: 'cloud-vps',
        redirectTo: 'cloud-vps/.',
        pathMatch: 'full'
    }, {
        path: 'cloud-vps-backup/.',
        component: VpsBackupComponent
    }, {
        path: 'cloud-vps-backup',
        redirectTo: 'cloud-vps-backup/.',
        pathMatch: 'full'
    }, {
        path: 'programa-auspicio/.',
        component: SponsorshipProgramComponent
    }, {
        path: 'programa-auspicio',
        redirectTo: 'programa-auspicio/.',
        pathMatch: 'full'
    }, {
        path: 'programa-afiliados/.',
        component: PartnersProgramComponent
    }, {
        path: 'programa-afiliados',
        redirectTo: 'programa-afiliados/.',
        pathMatch: 'full'
    }, {
        path: 'formas-de-pago/.',
        component: PaymentMethodsComponent
    }, {
        path: 'formas-de-pago',
        redirectTo: 'formas-de-pago/.',
        pathMatch: 'full'
    }, {
        path: 'centro-de-logotipos/.',
        component: LogoCenterComponent
    }, {
        path: 'centro-de-logotipos',
        redirectTo: 'centro-de-logotipos/.',
        pathMatch: 'full'
    }, {
        path: 'contacto/.',
        component: ContactComponent
    }, {
        path: 'contacto',
        redirectTo: 'contacto/.',
        pathMatch: 'full'
    }, {
        path: 'dominios/.',
        component: DomainComponent
    }, {
        path: 'dominios',
        redirectTo: 'dominios/.',
        pathMatch: 'full'
    }, {
        path: 'promo/.',
        component: PromoComponent
    }, {
        path: 'promo',
        redirectTo: 'promo/.',
        pathMatch: 'full'
    }, {
        path: 'pago-directo/.',
        component: DirectPaymentComponent
    }, {
        path: 'pago-directo',
        redirectTo: 'pago-directo/.',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
