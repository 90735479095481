import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { MetaModule } from '@ngx-meta/core';

import { NgwWowModule } from 'ngx-wow';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { WebHostingComponent } from './web-hosting/web-hosting.component';
import { ResellerComponent } from './reseller/reseller.component';
import { PymeComponent } from './pyme/pyme.component';
import { VpsComponent } from './vps/vps.component';
import { VpsBackupComponent } from './vps-backup/vps-backup.component';
import { SponsorshipProgramComponent } from './sponsorship-program/sponsorship-program.component';
import { PartnersProgramComponent } from './partners-program/partners-program.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { LogoCenterComponent } from './logo-center/logo-center.component';
import { ContactComponent } from './contact/contact.component';
import { DomainComponent } from './domain/domain.component';
import { PromoComponent } from './promo/promo.component';
import { SuccessComponent} from './success/success.component';

import { CurrencyFormatPipe } from './currency.pipe';
import { CustomMaterialModule } from '../../../common/material.module';
import { LocaleService } from './services/locale.service';
import { DomainService } from './services/domain.service';
import { SuccessGuard } from './success/success.guard';
import { DirectPaymentComponent } from './direct-payment/direct-payment.component';
import { SearchFormComponent } from './shared/header/search-form/search-form.component';
import { NoSearchResultComponent } from './direct-payment/no-search-result/no-search-result.component';
import { SearchResultComponent } from './direct-payment/search-result/search-result.component';
import { SearchService } from './services/search.service';
import { NgxCaptchaModule } from 'ngx-captcha';
@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        WebHostingComponent,
        ResellerComponent,
        PymeComponent,
        VpsComponent,
        VpsBackupComponent,
        SponsorshipProgramComponent,
        PartnersProgramComponent,
        PaymentMethodsComponent,
        LogoCenterComponent,
        ContactComponent,
        DomainComponent,
        PromoComponent,
        CurrencyFormatPipe,
        SuccessComponent,
        DirectPaymentComponent,
        SearchFormComponent,
        SearchResultComponent,
        NoSearchResultComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'hosty' }),
        BrowserAnimationsModule,
        HttpClientModule,
        MetaModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        NgwWowModule,
        CarouselModule,
        DeferLoadModule,
        CustomMaterialModule,
        TransferHttpCacheModule,
        NgxCaptchaModule,
        IconSpriteModule.forRoot({
            path: '/assets/images/sprites/sprite.svg'
        })
    ],
    providers: [
        CurrencyFormatPipe,
        LocaleService,
        DomainService,
        SuccessGuard,
        SearchService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
