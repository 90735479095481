export const CHARACTERISTICS_TABLE_CL = {
    web_hosting: {
        table_titles: [
            'Espacio Web SSD',
            'Transferencia mensual',
            'Dominios permitidos',
            '99.99 % Uptime',
            'Cuentas E-mail',
            'Bases de datos MySQL / PostgreSQL',
            'Soporte',
            'SubDominios',
            'cPanel',
            'Softaculous',
            'Anti-Spam Premium',
            'FireWall Web Premium'
        ],
        table_info: [
            {
                title: 'MICRO',
                list: [
                    '2GB',
                    'Ilimitada',
                    '1',
                    'Incluido',
                    'Ilimitadas',
                    '2',
                    'Incluido',
                    'Ilimitados',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                    'Incluido'
                ],
                price: 14900,
                link: 'https://panel.hosty.host/cart.php?a=add&pid=2&currency=1&tld=.cl&promocode=hosty30',
                btn_id: 'h-wh-micro'
            }, {
                title: 'MEDIANO',
                list: [
                    '5GB',
                    'Ilimitada',
                    '2',
                    'Incluido',
                    'Ilimitadas',
                    '4',
                    'Incluido',
                    'Ilimitados',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                    'Incluido'
                ],
                price: 29900,
                // tslint:disable-next-line:max-line-length
                link: 'https://panel.hosty.host/cart.php?a=add&pid=3&currency=1&tld=.cl',
                btn_id: 'h-wh-medio'
            }, {
                title: 'AVANZADO',
                list: [
                    '<a class="has-icon" href="https://help.haulmer.com/hc/conocimientos-generales/conoce-nuestra-politica-de-recursos-ilimitados" target="_blank">Ilimitado</a>',
                    'Ilimitada',
                    'Ilimitados',
                    'Incluido',
                    'Ilimitadas',
                    'Ilimitadas',
                    'Incluido',
                    'Ilimitados',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                    'Incluido'
                ],
                price: 32900,
                // tslint:disable-next-line:max-line-length
                link: 'https://panel.hosty.host/cart.php?a=add&pid=4&currency=1&tld=.cl',
                btn_id: 'h-wh-avanzado'
            }
        ]
    },
    reseller: {
        table_titles: [
            'Espacio Web',
            'Transferencia mensual',
            'Cuentas cPanel',
            'Cuentas E-mail',
            'Subdominios',
            'Bases de datos MySQL/PostgreSQL',
            'Site PubliSher / Sitio en Minutos',
            'WHM / cPanel',
            'WHM / cPanel Anónimo y Personalizable',
            'DNS Anónimo y Personalizable',
            'Softaculous'
        ],
        table_info: [
            {
                title: 'MICRO',
                list: [
                    '25GB',
                    '10GB',
                    'Ilimitadas',
                    'Ilimitadas',
                    'Ilimitados',
                    'Ilimitadas',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                ],
                price: 8000,
                link: 'https://panel.hosty.host/cart.php?a=add&pid=8&currency=1&tld=.cl',
                btn_id: 'h-r-micro'
            }, {
                title: 'MEDIANO',
                list: [
                    '50GB',
                    '10GB',
                    'Ilimitadas',
                    'Ilimitadas',
                    'Ilimitados',
                    'Ilimitadas',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                ],
                price: 16000,
                link: 'https://panel.hosty.host/cart.php?a=add&pid=9&currency=1&tld=.cl',
                btn_id: 'h-r-mediano'
            }, {
                title: 'AVANZADO',
                list: [
                    '100GB',
                    '10GB',
                    'Ilimitadas',
                    'Ilimitadas',
                    'Ilimitados',
                    'Ilimitadas',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                ],
                price: 20000,
                link: 'https://panel.hosty.host/cart.php?a=add&pid=10&currency=1&tld=.cl',
                btn_id: 'h-r-avanzado'
            }
        ]
    },
    pyme: {
        table_titles: [
            'Espacio Web SSD',
            'Transferencia mensual',
            'Dominios permitidos',
            '99.99% Uptime',
            'Cuentas E-mail',
            'Bases de datos MySQL/PostgreSQL',
            'Soporte',
            'SubDominios',
            'Inodos',
            'cPanel',
            'Softaculous',
            'Anti-Spam Premium',
            'FireWall Web Premium',
            'Soporte Preferencial Empresa'
        ],
        table_info: [
            {
                title: 'MICRO',
                list: [
                    '<a class="has-icon" href="https://help.haulmer.com/hc/conocimientos-generales/conoce-nuestra-politica-de-recursos-ilimitados" target="_blank">Ilimitado</a>',
                    'Ilimitada',
                    '1',
                    'Incluido',
                    'Ilimitadas',
                    'Ilimitadas',
                    'Incluido',
                    '2',
                    '400.000',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                    'Incluido'
                ],
                price: 49900,
                link: 'https://panel.hosty.host/cart.php?a=add&pid=5&currency=1&tld=.cl',
                btn_id: 'h-hp-micro'
            }, {
                title: 'MEDIANO',
                list: [
                    '<a class="has-icon" href="https://help.haulmer.com/hc/conocimientos-generales/conoce-nuestra-politica-de-recursos-ilimitados" target="_blank">Ilimitado</a>',
                    'Ilimitada',
                    '2',
                    'Incluido',
                    'Ilimitadas',
                    'Ilimitadas',
                    'Incluido',
                    '20',
                    '400.000',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                    'Incluido'
                ],
                price: 60900,
                link: 'https://panel.hosty.host/cart.php?a=add&pid=6&currency=1&tld=.cl',
                btn_id: 'h-hp-mediano'
            }, {
                title: 'AVANZADO',
                list: [
                    '<a class="has-icon" href="https://help.haulmer.com/hc/conocimientos-generales/conoce-nuestra-politica-de-recursos-ilimitados" target="_blank">Ilimitado</a>',
                    'Ilimitada',
                    '12',
                    'Incluido',
                    'Ilimitadas',
                    'Ilimitadas',
                    'Incluido',
                    'Ilimitados',
                    '400.000',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                    'Incluido',
                    'Incluido'
                ],
                price: 71900,
                link: 'https://panel.hosty.host/cart.php?a=add&pid=7&currency=1&tld=.cl',
                btn_id: 'h-hp-avanzado'
            }
        ]
    }
};
export const DOMAINS_PRICE_CL = {
    domaines: {
        table_titles: [
            '.CL',
            '.PE',
            'COM.PE',
            '.NET.PE',
            '.CO ',
            '.COM.CO',
            '.NET.CO',
            '.COM.AR',
            'COM.MX',
            //'COM.BR',
            '.COM',
            '.ORG',
            '.NET',
            '.PRO',
            '.LTDA',
            '.IO',
            '.BLOG',
            '.US',
            '.INFO',
            '.NAME',
            '.TV',
            '.BIZ'
        ],
        table_info: [
            {
                title: '1 Año',
                list: [
                    '8900',
                    '39900',
                    '39900',
                    '39900',
                    '29900',
                    '11900',
                    '11900',
                    '20900',
                    '12900',
                    //'23900',
                    '7900',
                    '7900',
                    '7900',
                    '14900',
                    '37900',
                    '36900',
                    '24900',
                    '7900',
                    '7900',
                    '7900',
                    '29900',
                    '7900'
                ]
            }, {
                title: '2 Años',
                list: [
                    '17800',
                    '79800',
                    '79800',
                    '79800',
                    '59800',
                    '23800',
                    '23800',
                    '41800',
                    '25800',
                    //'47800',
                    '15800',
                    '15800',
                    '15800',
                    '29800',
                    '75800',
                    '73800',
                    '49800',
                    '15800',
                    '15800',
                    '15800',
                    '59800',
                    '15800'
                ]
            }, {
                title: '3 Años',
                list: [
                    '26700',
                    '119700',
                    '119700',
                    '119700',
                    '89700',
                    '35700',
                    '35700',
                    '62700',
                    '38700',
                    //'71700',
                    '23700',
                    '23700',
                    '23700',
                    '44700',
                    '113700',
                    '110700',
                    '74700',
                    '23700',
                    '23700',
                    '23700',
                    '89700',
                    '23700'
                ]
            }, {
                title: '4 Años',
                list: [
                    '35600',
                    '-',
                    '-',
                    '-',
                    '-',
                    '-',
                    '-',
                    '-',
                    '-',
                    //'-',
                    '31600',
                    '31600',
                    '31600',
                    '-',
                    '-',
                    '-',
                    '-',
                    '31600',
                    '31600',
                    '31600',
                    '-',
                    '31600'
                ]
            }, {
                title: '5 Años',
                list: [
                    '44500',
                    '-',
                    '-',
                    '-',
                    '-',
                    '-',
                    '-',
                    '-',
                    '-',
                    //'-',
                    '39500',
                    '39500',
                    '39500',
                    '-',
                    '-',
                    '-',
                    '-',
                    '39500',
                    '39500',
                    '39500',
                    '-',
                    '39500'
                ]
            }
        ]
    }
};
export const PRICE_RECOMMEND_CL = [
    {
        zone: 'net',
        price: '7900',
        btn_id: '',
        btn_link: ''
    }, {
        zone: 'org',
        price: '',
        btn_id: '',
        btn_link: ''
    }, {
        zone: 'us',
        price: '',
        btn_id: '',
        btn_link: ''
    }, {
        zone: 'name',
        price: '9900',
        btn_id: '',
        btn_link: ''
    }, {
        zone: 'info',
        price: '',
        btn_id: '',
        btn_link: ''
    }, {
        zone: 'pe',
        price: '',
        btn_id: '',
        btn_link: ''
    }, {
        zone: 'co',
        price: '',
        btn_id: '18000',
        btn_link: ''
    }, {
        zone: 'me',
        price: '',
        btn_id: '',
        btn_link: ''
    }
];
export const DATA_PLANS_CL = {
    home: [
        {
            title: 'HOSTING',
            card: {
                desc: 'Dale marcha a tus <strong>sueños</strong>',
                list: [
                    '2 GB Espacio',
                    '1 Dominio Hospedado',
                    'Ilimitada transferencia',
                    '2 Bases de datos',
                    'Correos ilimitados',
                    'Control seguridad',
                    'Soporte técnico'
                ]
            },
            price: 14900,
            btn_id: 'h-h-hosting',
            route: '/web-hosting'
        }, {
            title: 'HOSTING PYME',
            card: {
                desc: 'Estabilidad y <strong>exclusividad</strong>',
                list: [
                    '<a class="has-icon" href="https://help.haulmer.com/hc/conocimientos-generales/conoce-nuestra-politica-de-recursos-ilimitados" target="_blank">Ilimitado Espacio</a>',
                    '1 Dominio Hospedado',
                    'Ilimitada transferencia',
                    'Ilimitadas Bases de datos',
                    'Correos ilimitados',
                    'Control seguridad',
                    'Soporte técnico'
                ]
            },
            price: 49900,
            btn_id: 'h-h-pyme',
            route: '/web-hosting-pyme'
        }, {
            title: 'VPS',
            card: {
                desc: 'Máximo <strong>rendimiento</strong> y control',
                list: [
                    '15GB Disco Duro',
                    '1 GB RAM',
                    '1.000 GB Transferencia',
                    '1 CPU - CORE',
                    'Correos Ilimitados',
                    'Control seguridad',
                    'Soporte técnico'
                ]
            },
            price: 2500,
            btn_id: 'h-h-vps',
            route: '/cloud-vps'
        }
    ],
    vps: [
        {
            title: 'PLAN VPS 1',
            card: {
                desc: '<strong>Características</strong>',
                list: [
                    '20GB Disco Duro',
                    '1 TB Tráfico Mensual',
                    '1 GB RAM',
                    '1 CPU - CORE',
                    '1 Dirección IP',
                    '99.99% Uptime',
                ],
                list_with_backup: [
                    '20GB Disco Duro',
                    '1 TB Tráfico Mensual',
                    '1 GB RAM',
                    '1 CPU - CORE',
                    'BACKUP',
                    '1 Dirección IP',
                    '99.99% Uptime',
                ]
            },
            price: 2500,
            price_with_backup:3000,
            btn_id: 'h-vps-1',
            link: 'https://panel.hosty.host/cart.php?a=add&pid=74&currency=1',
            link_with_backup: 'https://panel.hosty.host/cart.php?a=add&pid=85&currency=1',
            class: 'fadeInLeft',
        }, {
            title: 'PLAN VPS 7',
            card: {
                desc: '<strong>Características</strong>',
                list: [
                    '640 GB Disco Duro',
                    '7 TB Tráfico Mensual',
                    '32 GB RAM',
                    '8 CPU - CORE',
                    '1 Dirección IP',
                    '99.99% Uptime',
                ],
                list_with_backup: [
                    '640 GB Disco Duro',
                    '7 TB Tráfico Mensual',
                    '32 GB RAM',
                    '8 CPU - CORE',
                    'BACKUP',
                    '1 Dirección IP',
                    '99.99% Uptime',
                ]
            },
            price: 100000,
            price_with_backup:120000,
            btn_id: 'h-vps-7',
            link: 'https://panel.hosty.host/cart.php?a=add&pid=79&currency=1',
            link_with_backup: 'https://panel.hosty.host/cart.php?a=add&pid=90&currency=1',
            class: 'fadeInRight',
        }, {
            title: 'PLAN VPS 12',
            card: {
                desc: '<strong>Características</strong>',
                list: [
                    '3840 GB Disco Duro',
                    '12 TB Tráfico Mensual',
                    '192 GB RAM',
                    '32 CPU - CORE',
                    '1 Dirección IP',
                    '99.99% Uptime',
                ],
                list_with_backup: [
                    '3840 GB Disco Duro',
                    '12 TB Tráfico Mensual',
                    '192 GB RAM',
                    '32 CPU - CORE',
                    'BACKUP',
                    '1 Dirección IP',
                    '99.99% Uptime',
                ]
            },
            price: 600000,
            price_with_backup:720000,
            btn_id: 'h-vps-4',
            link: 'https://panel.hosty.host/cart.php?a=add&pid=84&currency=1',
            link_with_backup: 'https://panel.hosty.host/cart.php?a=add&pid=95&currency=1',
            class: 'fadeInUp',
        }
    ]
};
export const DETAIL_PLANS_CL = {
    head: [
        'DISCO DURO',
        'TRÁFICO MENSUAL',
        'RAM',
        'CPU-CORES',
        'IP',
        'VALOR(30%Dcto)',
        ''
    ],
    row: [
        {
            cell: [
                '20GB',
                '1 TB',
                '1 GB',
                '1',
                '1'
            ],
            price: 2500,
            price_with_backup:3000,
            link: 'https://panel.hosty.host/cart.php?a=add&pid=74&currency=1',
            link_with_backup: 'https://panel.hosty.host/cart.php?a=add&pid=85&currency=1',
            btn_id: 'h-vps-1gb'
        }, {
            cell: [
                '50GB',
                '2 TB',
                '2 GB',
                '1',
                '1'
            ],
            price: 6000,
            price_with_backup:7200,
            link: 'https://panel.hosty.host/cart.php?a=add&pid=75&currency=1',
            link_with_backup: 'https://panel.hosty.host/cart.php?a=add&pid=86&currency=1',
            btn_id: 'h-vps-2gb'
        }, {
            cell: [
                '80GB',
                '4 TB',
                '4 GB',
                '2',
                '1'
            ],
            price: 12000,
            price_with_backup:14400,
            link: 'https://panel.hosty.host/cart.php?a=add&pid=76&currency=1',
            link_with_backup: 'https://panel.hosty.host/cart.php?a=add&pid=87&currency=1',
            btn_id: 'h-vps-4gb'
        }, {
            cell: [
                '160GB',
                '5 TB',
                '8 GB',
                '4',
                '1'
            ],
            price: 25000,
            price_with_backup:30000,
            link: 'https://panel.hosty.host/cart.php?a=add&pid=77&currency=1',
            link_with_backup: 'https://panel.hosty.host/cart.php?a=add&pid=88&currency=1',
            btn_id: 'h-vps-8gb'
        }, {
            cell: [
                '320GB',
                '6 TB',
                '16 GB',
                '6',
                '1'
            ],
            price: 50000,
            price_with_backup:60000,
            link: 'https://panel.hosty.host/cart.php?a=add&pid=78&currency=1',
            link_with_backup: 'https://panel.hosty.host/cart.php?a=add&pid=89&currency=1',
            btn_id: 'h-vps-16gb'
        }, {
            cell: [
                '640GB',
                '7 TB',
                '32 GB',
                '8',
                '1'
            ],
            price: 100000,
            price_with_backup:120000,
            link: 'https://panel.hosty.host/cart.php?a=add&pid=79&currency=1',
            link_with_backup: 'https://panel.hosty.host/cart.php?a=add&pid=90&currency=1',
            btn_id: 'h-vps-32gb'
        }, {
            cell: [
                '960GB',
                '8 TB',
                '48 GB',
                '12',
                '1'
            ],
            price: 150000,
            price_with_backup:180000,
            link: 'https://panel.hosty.host/cart.php?a=add&pid=80&currency=1', //this link must be replaced
            link_with_backup: 'https://panel.hosty.host/cart.php?a=add&pid=91&currency=1',
            btn_id: 'h-vps-48gb'
        }, {
            cell: [
                '1280GB',
                '9 TB',
                '64 GB',
                '16',
                '1'
            ],
            price: 200000,
            price_with_backup:240000,
            link: 'https://panel.hosty.host/cart.php?a=add&pid=81&currency=1',
            link_with_backup:'https://panel.hosty.host/cart.php?a=add&pid=92&currency=1',
            btn_id: 'h-vps-64gb'
        }, {
            cell: [
                '1920GB',
                '10 TB',
                '96 GB',
                '20',
                '1'
            ],
            price: 300000,
            price_with_backup:360000,
            link: 'https://panel.hosty.host/cart.php?a=add&pid=82&currency=1',
            link_with_backup: 'https://panel.hosty.host/cart.php?a=add&pid=93&currency=1',
            btn_id: 'h-vps-64gb'
        }, {
            cell: [
                '2560GB',
                '11 TB',
                '128 GB',
                '24',
                '1'
            ],
            price: 400000,
            price_with_backup:480000,
            link: 'https://panel.hosty.host/cart.php?a=add&pid=83&currency=1',
            link_with_backup: 'https://panel.hosty.host/cart.php?a=add&pid=94&currency=1',
            btn_id: 'h-vps-64gb'
        }, {
            cell: [
                '3840GB',
                '12 TB',
                '192 GB',
                '32',
                '1'
            ],
            price: 600000,
            price_with_backup:720000,
            link: 'https://panel.hosty.host/cart.php?a=add&pid=84&currency=1',
            link_with_backup: 'https://panel.hosty.host/cart.php?a=add&pid=95&currency=1',
            btn_id: 'h-vps-64gb'
        }
    ]
};
export const CONTENT_CL= {
    currencyCode: 'CLP',
    HOME:{
        slidesStore: [
            {
                id: 1,
                name: 'Javier Brauer',
                // tslint:disable-next-line:max-line-length
                text: 'Me alegro mucho de haber confiado en Hosty. Siempre han sido diligentes con mis consultas y no he tenido problemas con mis sitios.Se agradece la estabilidad y el buen servicio que ofrecen, su soporte siempre ha sido eficiente y respetuoso, junto con darme respuestas rápidas a los problemas o dudas que he tenido.'
            }, {
                id: 2,
                name: 'Victor Castillo',
                // tslint:disable-next-line:max-line-length
                text: 'Llegué a Hosty, recomendado por un amigo... y fue tremendo dato! De verdad recontento por el servicio.Super rápidos y eficientes.Un agrado poder trabajar tranquilo y confiado en no tener problemas o caídas.'
            }, {
                id: 3,
                name: 'Guillermo Altamirano',
                // tslint:disable-next-line:max-line-length
                text: 'He usado hartos servicios de hosting, pero Hosty ha sido de los que más conformes me ha dejado. Mas que feliz con el servicio, además de todas las herramientas y ayuda que ofrecen para hacer el trabajo más fácil.'
            }
        ],
    },
    CONTACT: {
        features: [
            {
                id: 1,
                title: 'Soporte Vía Mail',
                text: 'Escribe tus dudas o consultas a ',
                link: 'mailto:venta@hosty.cl',
                text_link: 'ENVIAR E-MAIL',
                class_link: 'small radius email button',
                img: 'email-mockup'
            }, {
                id: 2,
                title: 'Live Chat',
                text: 'Comunicate directamente con nuestros ejecutivos vía chat.',
                text_link: 'ENTRA AL CHAT',
                class_link: 'slaask-open-widget',
                img: 'chat-mockup',
                class: 'fadeInLeft'
            }, {
                id: 3,
                title: 'Call Center',
                text: 'Nuestras líneas están abiertas para escuchar tus solicitudes. Llámanos al',
                link: 'tel:+56225739464',
                text_link: 'LLAMAR AHORA',
                class_link: 'small radius phone button',
                img: 'phone-mockup'
            }, {
                id: 4,
                title: 'Documentación',
                // tslint:disable-next-line:max-line-length
                text: 'Hemos puesto a tu disposición la mas amplia gama de manuales, tutoriales y videos, ingresa y podrás resolver todas tus dudas',
                link: 'https://docs.hosty.cl/',
                text_link: 'Ingresar',
                class_link: 'small radius ticket button',
                img: 'ticket-mockup'
            }
        ],
    },
    LOGO_CENTER: {
        logos: [
            {
                title: 'BANNERS 300 x 250',
                images: [
                    'hosty_datacenter300x250',
                    'hosty_persona300x250',
                    'hosty_reseller300x250',
                    'hosty_vps300x250'
                ],
                class: 'large-block-grid-2',
                info: [
                    'Link descarga datacenter300x250',
                    'Link descarga persona300x250',
                    'Link descarga reseller300x250',
                    'Link descarga vps300x250',
                ]
            }, {
                title: 'BANNERS 728 x 90',
                images: [
                    'hosty_datacenter728x90',
                    'hosty_persona728x90',
                    'hosty_reseller728x90',
                    'hosty_vps728x90'
                ],
                class: 'large-block-grid-1',
                info: [
                    'Link descarga datacenter728x90',
                    'Link descarga persona728x90',
                    'Link descarga reseller728x90',
                    'Link descarga vps728x90',
                ]
            }
        ],
    },
    PARTNERS:{
        steps: [
            {
                title: 'INSCRÍBETE',
                text: '¡Inscríbete hoy! Es gratis.',
                img: 'vps_step_1'
            }, {
                title: 'PUBLICA',
                text: '¡Pon nuestros banners, publica y difunde!',
                img: 'vps_step_2'
            }, {
                title: 'GANA DINERO',
                text: 'Obtendrás un 10% por cada compra que hagan tus referidos en nuestro sitio',
                img: 'vps_step_3'
            }
        ],

        partnersProgram: [
            {
                title: 'BENEFICIO PROGRAMA DE AFILIADOS',
                list: [
                    'Gana 10% de todas las ventas referidas.',
                    'Inscríbete Hoy. Es Rápido y Fácil.',
                    'Publica en Cualquier Lugar. Blogs, Clasificados, Anuncios Gratis, etc.'
                ]
            }, {
                title: 'PUBLICA EN CUALQUIER LUGAR',
                list: [
                    // tslint:disable-next-line:max-line-length
                    'Al Momento de inscribirte te daremos una dirección web con una ID única de Afiliado, puedes publicar esta dirección web en cualquier lugar: blogs, posteos, foros, clasificados, anuncios gratis, etc.',
                    'Elige diferentes tipos de banner que puedes publicar en tu sitio web o en cualquier otro lugar.'
                ]
            }, {
                title: '¿CÓMO SABE HOSTY QUE YO REFERÍ LA VENTA?',
                list: [
                    // tslint:disable-next-line:max-line-length
                    'Cuando usted nos refiere alguna persona a nuestra web usando su ID única de Afiliado, una cookie con su ID es colocada en el PC de la persona referida.De esta manera, si la persona que usted nos refirió vuelve a visitar nuestra web, nuestro sistema detecta la cookie y asigna la comisión en su cuenta de afiliado.'
                ]
            }, {
                title: '¿CÓMO ME PAGAN MI COMISIÓN',
                list: [
                    'Transferencia Bancaría Bancos Chilenos.',
                    'DineroMail Chile.',
                    'Paypal.'
                ]
            }, {
                title: '¿CUÁNDO ME PAGAN MI COMISIÓN?',
                list: [
                    'Su comisión se paga dos veces al mes, entre el 1 al 5 y 15 al 20'
                ]
            }
        ],
    },
    PAYMENT:{
        payments: [
            {
                title: 'PAGOMASTER',
                info: [
                    {
                        title: 'Tarjetas de Crédito / Tiempo de Activación del Servicio INMEDIATA*',
                        class: 'large-block-grid-5',
                        images: [
                            'visa',
                            'mastercard',
                            'american_express',
                            'diners',
                            'magna'
                        ]
                    }, {
                        title: 'Tarjetas de Débito / Tiempo de Activación del Servicio INMEDIATA*',
                        class: 'large-block-grid-7',
                        images: [
                            'ripley',
                            'bci',
                            'banco_chile',
                            'bbva',
                            'bice',
                            'edwards',
                            'banco_estado',
                            'falabella',
                            'scotiabank',
                            'security',
                            'hsbc',
                            'internacional',
                            'cmr',
                            'cencosud'
                        ]
                    }, {
                        title: 'RedCompra / Tiempo de Activación del Servicio INMEDIATA*',
                        class: 'large-block-grid-1',
                        images: [
                            'redcompra'
                        ]
                    }

                ]
            }, {
                title: 'TRANSFERENCIAS',
                info: [
                    {
                        title: 'Transferencia / Tiempo de Activación del Servicio INMEDIATA',
                        class: 'large-block-grid-1',
                        // tslint:disable-next-line:max-line-length
                        //text: '(Recuerde que debe Notificar su Pago, enviando su Comprobante de Deposito o en el caso de una transferencia bancaria enviar una captura de Pantalla del momento en que la transferencia se haya realizado exitosamente en formato PDF o JPG.Debe enviarlo desde su Área Cliente, viñeta "Enviar ticket", seleccionando el Departamento de Pago)',
                        images: [
                            'logo-linkify'
                        ]
                    }
                ]
            }, {
                title: 'PAYPAL',
                info: [
                    {
                        title: 'Con PayPal paga con tus tarjetas de crédito /Tiempo de Activación del Servicio INMEDIATA*',
                        class: 'large-block-grid-1',
                        images: [
                            'paypal'
                        ]
                    }
                ]
            }
        ],
    },
    PYME:{
        domainfeatures: [
            {
                title: 'CDN',
                // tslint:disable-next-line:max-line-length
                text: 'Replica tus archivos y datos, ubicados en varios puntos de una red para lograr bajo tiempo de respuesta y mínima pérdida de información.'
            }, {
                title: 'Respaldos',
                // tslint:disable-next-line:max-line-length
                text: 'Protegemos tus archivos frente a cualquier eventualidad con nuestro servicio de respaldo diario, semanal y mensual.'
            }, {
                title: 'Datacenter',
                // tslint:disable-next-line:max-line-length
                text: 'Mantén tus archivos resguardados, junto con más recursos enfocados a dar un servicio más rápido y estable'
            }, {
                title: 'Inodos',
                // tslint:disable-next-line:max-line-length
                text: 'Es la cantidad de archivos que puedes almacenar en un servidor, sin importar el tamaño o tipo de archivo que sea.'
            }
        ],

        additionalProducts: [
            // {
            //     img: 'add-features-1',
            //     title: 'CONFIGURACIÓN DE GOOGLE APPS',
            //     price: 5900,
            //     // tslint:disable-next-line:max-line-length
            //     text: 'Ahorra tiempo y dinero con una plataforma más amigable, estable y completa, donde concentrarás todos los servicios en uno. Además, nuestro equipo se encarga de configurar tu correo con tu dominio en Google Apps.',
            //     sub_text: 'SE PAGA UNA SOLA VEZ',
            //     class: 'fadeInLeft',
            // },
            {
                img: 'add-features-8',
                title: 'PERSONALIZACIÓN DE TUS DNS',
                price: 9900,
                // tslint:disable-next-line:max-line-length
                text: 'Los DNS son imprescindibles para ser visible en internet. Nuestro equipo de expertos configura tus DNS propios de tu dominio en 24 hrs máximo. Los DNS se encargan de la resolución entre nombres de host y direcciones de internet, por lo que tu dominio y correos serán visibles en cualquier parte del mundo.',
                sub_text: 'SE PAGA UNA SOLA VEZ',
                class: 'fadeInRight',
            }, {
                img: 'add-features-2',
                title: 'MIGRACIÓN DE HOSTING',
                price: 9900,
                // tslint:disable-next-line:max-line-length
                text: 'Migramos completamente tu sitio desde tu antiguo hosting al nuestro. Todos tus archivos, correos electrónicos, softwares, scripts, etc. serán migrados de manera segura y rápida, para que tu sitio quede funcionando en cosa de horas.',
                sub_text: 'SE PAGA UNA SOLA VEZ',
                class: 'fadeInLeft',
            }
            // , {
            //     img: 'add-features-3',
            //     title: 'IP DEDICADA',
            //     price: 18900,
            //     // tslint:disable-next-line:max-line-length
            //     text: 'Entre las variadas ventajas de tener una IP dedicada, te ayudará al posicionamiento de tu sitio web en google, junto con obtener un certificado SSL. Además podrás enviar correos de tu propia IP y te ayudará para la seguridad de tu sitio',
            //     sub_text: 'SE PAGA MENSUAL',
            //     class: 'fadeInRight',
            // }
            , {
                img: 'add-features-4',
                title: 'SOFTWARE DE CREACIÓN DE SITE MAPS',
                price: 9900,
                // tslint:disable-next-line:max-line-length
                text: 'Nuestro equipo se encargará de instalar y configurar el software de creación de Site Maps ideal para el posicionamiento de tu sitio web. Así, de forma diaria, semanal y mensual se creará automáticamente un Site Maps que se agregará a Google, Yahoo y Bing.',
                sub_text: 'SE PAGA UNA SOLA VEZ',
                class: 'fadeInLeft',
            }, {
                img: 'add-features-5',
                title: 'INSTALACIÓN DE JOOMLA',
                price: 2900,
                // tslint:disable-next-line:max-line-length
                text: 'Joomla es uno de los sistemas dinámicos de contenidos más usado en el mundo, que te permite personalizar y escalar tu sitio como tu lo desees, de manera rápida y sencilla.',
                sub_text: 'SE PAGA UNA SOLA VEZ',
                class: 'fadeInRight',
            }, {
                img: 'add-features-6',
                title: 'INSTALACIÓN DE WORDPRESS',
                price: 2900,
                // tslint:disable-next-line:max-line-length
                text: 'WordPress es un sistema de gestión de contenido usado preferentemente para la creación de blogs, debido a que es muy fácil de utilizar. Como es un software de licencia libre, puedes cambiar cuantas veces quieras el diseño de tu sitio sin tener que cambiar su formato.',
                sub_text: 'SE PAGA UNA SOLA VEZ',
                class: 'fadeInLeft',
            }
        ]
    },
    RESELLER: {
        additionalProducts: [
            // {
            //     img: 'add-features-3',
            //     title: 'IP DEDICADA',
            //     price: 18900,
            //     // tslint:disable-next-line:max-line-length
            //     text: 'Entre las variadas ventajas de tener una IP dedicada, te ayudará al posicionamiento de tu sitio web en google, junto con obtener un certificado SSL. Además podrás enviar correos de tu propia IP y te ayudará para la seguridad de tu sitio',
            //     sub_text: 'SE PAGA MENSUAL',
            //     class: 'fadeInRight',
            // }
        ],
    },
    SPONSORSHIP:{
        faqcategory: [
            {
                title: '¿EN QUÉ CONSISTE EL PROGRAMA?',
                // tslint:disable-next-line:max-line-length
                text: 'HOSTY entregará, tomando en cuenta las características de su sitio, un descuento en su hospedaje web. Este auspicio puede variar desde un servidor compartido hasta un servidor dedicado con las características óptimas para entregar el mejor rendimiento a sus proyectos.Por nuestra parte, Hosty solicita poder agregar el siguiente enlace al pie de su sitio web, "Sitio hospedado por HOSTY | Web Hosting Chile |".'
            }, {
                title: 'TENGO OTRO HOSTING, ¿CÓMO TRANSFIERO MI SITIO?',
                // tslint:disable-next-line:max-line-length
                text: 'Si su sitio se encuentra hospedado por otro proveedor, nuestros profesionales se encargarán de realizar la transferencia de su web al nuevo servidor otorgado en el auspicio.'
            }
        ],
    },
    VPS:{
        additionalProducts: [
            {
                img: 'server-features-2',
                title: 'LICENCIA WHM / CPANEL',
                price: 20000,
                // tslint:disable-next-line:max-line-length
                text: 'WHM es el panel lider en el mercado te permitirá administrar tu sitio y también a tus clientes, con una interfaz limpia y sencilla de usar, donde podrás obtener informes y estadísticas detalladas de tu sitio.',
                sub_text: 'PAGO MENSUAL ',
                class: 'fadeInLeft',
            }, {
                img: 'add-features-9',
                title: 'LICENCIA SOFTACULOUS',
                price: 1000,
                // tslint:disable-next-line:max-line-length
                text: 'Software con más de 250 scripts autoinstalables como blogs, foros y sistemas de gestión de contenido para facilitar tus proyectos y sitios.',
                sub_text: 'PAGO MENSUAL',
                class: 'fadeInRight',
            }, {
                img: 'add-features-11',
                title: 'PACK VPS TODO LISTO',
                price: 25900,
                // tslint:disable-next-line:max-line-length
                text: 'Servicio de Configuración de tu VPS que incluye la Optimización, Configuración de Seguridad, Migración y Configuración de DNS - Para la realización de esta configuración, su VPS debe tener en su Antiguo y en Hosty, Licencia cPanel (Producto Opcional) (Recuerde que su servicio antiguo debe estar activo y debe quedarle vigencia de por lo menos 1 semana para realizar la migración con seguridad)',
                sub_text: 'SE PAGA SOLO UNA VEZ',
                class: 'fadeInLeft',
            }
        ],

        featuresVps: [
            {
                img: 'server-features-1',
                title: 'Datacenter',
                // tslint:disable-next-line:max-line-length
                text: 'Siempre preocupados de cumplir con los más altos estandares , contamos con enlace de fibra optica,sistema de refrigeración redundante, sistema de respaldo eléctrico, junto con que nuestros servidores cuentan con ups.',
                class: 'fadeInLeft',
            }, {
                img: 'server-features-2',
                title: 'Tecnología de Administración',
                // tslint:disable-next-line:max-line-length
                text: 'Incrementa la eficiencia y potencia de su centro de datos, lo que te permitirá trabajar con múltiples aplicaciones y sistemas operativos.Mayor rapidez, rendimiento, disponibilidad y las operaciones se automatizan a menor costo.',
                class: 'fadeInRight',
            }, {
                img: 'server-features-3',
                title: 'Administración VPS',
                // tslint:disable-next-line:max-line-length
                text: 'Tendrás total control de tu VPS, lo que te permitirá reiniciar, apagar, prender y modificar con acceso completo Root, a través de SSH y Consola.Contarás con estadisticas y mediciones del uso de CPU, RAM y transferencia de tu VPS.',
                class: 'fadeInLeft',
            }, {
                img: 'server-features-4',
                title: 'Softwares disponibles',
                // tslint:disable-next-line:max-line-length
                text: 'Contarás con variadas herramientas para tu VPS como CentOS, Debian, Slackware, Fedora y Ubuntu.',
                class: 'fadeInRight',
            }, {
                img: 'server-features-5',
                title: 'Flexibilidad',
                // tslint:disable-next-line:max-line-length
                text: 'Nuestros VPS son totalmente flexibles, lo que te dará la libertad de instalar, modificar y optimizar cualquier tipo de servicio como un servidor web, servidor de juegos, servidor de respaldo, entre otros.',
                class: 'fadeInLeft',
            }, {
                img: 'server-features-6',
                title: 'Uptime 99.99%',
                // tslint:disable-next-line:max-line-length
                text: 'Buscamos dar un servicio estable y de calidad, por lo que monitoreamos constantemente nuestros servidores, atentos a que tengas la mejor funcionalidad en tu servicio.',
                class: 'fadeInRight',
            }
        ],
    },
    WEB_HOSTING:{
        domainfeatures: [
            {
                title: 'CPU / 700Mhz',
                // tslint:disable-next-line:max-line-length
                text: 'Establece el límite de uso de CPU por cada usuario del servidor, mejora el rendimiento de procesos que requieran un alto uso de CPU, como operaciones aritméticas y procesamiento de arreglos (arrays) y de conexiones a la base de datos.'
            }, {
                title: 'MEMORIA RAM - 500Mb',
                // tslint:disable-next-line:max-line-length
                text: 'Si el valor es alto más gente puede ingresar al sitio de manera simultánea y la velocidad de carga aumenta para cada visitante. Esto puede variar de acuerdo ha como tengas optimizado tu servicio y sitio web ademas de el uso que le des a este.'
            }, {
                title: 'PROCESOS DE ENTRADA / 7',
                // tslint:disable-next-line:max-line-length
                text: 'Si el valor es alto más gente puede ingresar al sitio de manera simultánea. Este valor determina la cantidad máxima de visitantes simultáneos que puede recibir su sitio web.'
            }, {
                title: 'INODOS / 50.000',
                // tslint:disable-next-line:max-line-length
                text: 'Determina la cantidad de número de archivos que puede almacenar en el espacio web de tu servicio, ten en cuenta que cada archivo representa un inodo en el disco duro del servidor.'
            }
        ],

        additionalProducts: [
            // {
            //     img: 'add-features-1',
            //     title: 'CONFIGURACIÓN DE GOOGLE APPS',
            //     price: 5900,
            //     // tslint:disable-next-line:max-line-length
            //     text: 'Ahorra tiempo y dinero con una plataforma más amigable, estable y completa, donde concentrarás todos los servicios en uno. Además, nuestro equipo se encarga de configurar tu correo con tu dominio en Google Apps.',
            //     sub_text: 'SE PAGA UNA SOLA VEZ',
            //     class: 'fadeInLeft',
            // },
            {
                img: 'add-features-8',
                title: 'PERSONALIZACIÓN DE TUS DNS',
                price: 9900 ,
                // tslint:disable-next-line:max-line-length
                text: 'Los DNS son imprescindibles para ser visible en internet. Nuestro equipo de expertos configura tus DNS propios de tu dominio en 24 hrs máximo. Los DNS se encargan de la resolución entre nombres de host y direcciones de internet, por lo que tu dominio y correos serán visibles en cualquier parte del mundo.',
                sub_text: 'SE PAGA UNA SOLA VEZ',
                class: 'fadeInRight',
            },
            {
                img: 'add-features-2',
                title: 'MIGRACIÓN DE HOSTING',
                price: 9900,
                // tslint:disable-next-line:max-line-length
                text: 'Migramos completamente tu sitio desde tu antiguo hosting al nuestro. Todos tus archivos, correos electrónicos, softwares, scripts, etc. serán migrados de manera segura y rápida, para que tu sitio quede funcionando en cosa de horas.',
                sub_text: 'SE PAGA UNA SOLA VEZ',
                class: 'fadeInLeft',
            },
            // {
            //     img: 'add-features-3',
            //     title: 'IP DEDICADA',
            //     price: 18900,
            //     // tslint:disable-next-line:max-line-length
            //     text: 'Entre las variadas ventajas de tener una IP dedicada, te ayudará al posicionamiento de tu sitio web en google, junto con obtener un certificado SSL. Además podrás enviar correos de tu propia IP y te ayudará para la seguridad de tu sitio',
            //     sub_text: 'SE PAGA MENSUAL',
            //     class: 'fadeInRight',
            // },
            {
                img: 'add-features-4',
                title: 'SOFTWARE DE CREACIÓN DE SITE MAPS',
                price: 9900,
                // tslint:disable-next-line:max-line-length
                text: 'Nuestro equipo se encargará de instalar y configurar el software de creación de Site Maps ideal para el posicionamiento de tu sitio web. Así, de forma diaria, semanal y mensual se creará automáticamente un Site Maps que se agregará a Google, Yahoo y Bing.',
                sub_text: 'SE PAGA UNA SOLA VEZ',
                class: 'fadeInLeft',
            },
            {
                img: 'add-features-5',
                title: 'INSTALACIÓN DE JOOMLA',
                price: 2900,
                // tslint:disable-next-line:max-line-length
                text: 'Joomla es uno de los sistemas dinámicos de contenidos más usado en el mundo, que te permite personalizar y escalar tu sitio como tu lo desees, de manera rápida y sencilla.',
                sub_text: 'SE PAGA UNA SOLA VEZ',
                class: 'fadeInRight',
            },
            {
                img: 'add-features-6',
                title: 'INSTALACIÓN DE WORDPRESS',
                price: 2900,
                // tslint:disable-next-line:max-line-length
                text: 'WordPress es un sistema de gestión de contenido usado preferentemente para la creación de blogs, debido a que es muy fácil de utilizar. Como es un software de licencia libre, puedes cambiar cuantas veces quieras el diseño de tu sitio sin tener que cambiar su formato.',
                sub_text: 'SE PAGA UNA SOLA VEZ',
                class: 'fadeInLeft',
            }
        ],
    },tdls: [
        'cl',
        'pe',
        'com.pe',
        'net.pe',
        'co',
        'com.co',
        'net.co',
        'com.ar',
        'com.mx',
        //'com.br',
        'com',
        'org',
        'net',
        'pro',
        'ltda',
        'io',
        'blog',
        'us',
        'info',
        'name',
        'tv',
        'biz'
        ],
};

