<footer>
  <div class="row">
    <div class="small-12 columns">
      <div class="contacts">
        <div class="row">
          <div class="small-12 large-3 medium-3 columns">
            <a [routerLink]="['/formas-de-pago']" (click)="scrollTop()"><mat-icon>credit_card</mat-icon></a>
            FORMAS DE PAGO
          </div>

          <div class="small-12 large-3 medium-3 columns">
            <a href="tel:{{data_footer.tel}}"><mat-icon>smartphone</mat-icon></a>
            {{data_footer.phone}}
          </div>

          <div class="small-12 large-3 medium-3 columns">
            <a class="link_chat slaask-open-widget"><mat-icon>question_answer</mat-icon></a>
            LIVE CHAT
          </div>

          <div class="small-12 large-3 medium-3 columns">
            <a href="http://panel.hosty.host/submitticket.php?step=2&amp;deptid=8"><mat-icon>mail_outline</mat-icon></a>
            ESCRIBENOS
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="small-12 columns">
      <div class="footerlinks">
        <div class="small-12 large-3 medium-3 columns border-right">
          <h2>Planes Hosting</h2>

          <ul>
            <li><a [routerLink]="['/web-hosting']" (click)="scrollTop()">Hosting</a></li>
            <li><a [routerLink]="['/web-hosting-pyme']" (click)="scrollTop()">PYME</a></li>
            <li><a [routerLink]="['/cloud-vps']" (click)="scrollTop()">VPS</a></li>
            <li><a [routerLink]="['/web-hosting-reseller']" (click)="scrollTop()">Reseller</a></li>
            <li><a [routerLink]="['/dominios']" (click)="scrollTop()">Dominios</a></li>
          </ul>
        </div>

        <div class="small-12 large-3 medium-3 columns border-right">
          <h2>Soporte y ayuda</h2>

          <ul>
            <li><a href="https://docs.hosty.{{tld}}">Base de Conocimiento</a></li>
            <li><a href="https://www.youtube.com/channel/UC9yYDe-kzsfAqrIhLr0kgoA">Video Tutoriales</a></li>
            <li><a href="https://docs.hosty.{{tld}}">Ayuda y tutoriales</a></li>
            <li><a href="https://panel.hosty.host/">Área Cliente</a></li>
            <li><a href="https://panel.hosty.host/submitticket.php">Notificación de pago</a></li>
          </ul>
        </div>

        <div class="small-12 large-3 medium-3 columns border-right">
          <h2>Compañía</h2>

          <ul>
            <li><a [href]="URL.HOSTY_TERMS_CONDITIONS">Términos y Condiciones</a></li>
            <li><a href="https://www.hosty.{{tld}}/blog">Blog</a></li>
            <li><a [routerLink]="['/programa-afiliados']" (click)="scrollTop()">Programa de afiliados</a></li>
            <li><a [routerLink]="['/programa-auspicio']" (click)="scrollTop()">Programa auspicio</a></li>
            <li><a [routerLink]="['/centro-de-logotipos']" (click)="scrollTop()">Centro de Logotipos</a></li>
          </ul>
        </div>

        <div class="small-12 large-3 medium-3 columns">
          <h2>Nuestro Newsletter</h2>

          <p>Inscribite para novedades y ofertas:</p>

          <!-- Begin MailChimp Signup Form -->
          <div id="mc_embed_signup">
            <form action="//audemedia.us7.list-manage.com/subscribe/post?u=b5638e105dac814ad84960d90&id=9345afa0aa"
                  class="validate"
                  id="mc-embedded-subscribe-form"
                  method="post"
                  name="mc-embedded-subscribe-form"
                  novalidate=""
                  target="_blank"
                  ngNoForm>
              <input class="email" id="mce-EMAIL" name="EMAIL" placeholder="Su correo electrónico" required="" type="email" value="">
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups -->
              <div style="position: absolute; left: -5000px;">
                <input name="b_b5638e105dac814ad84960d90_9345afa0aa" tabindex="-1" type="text" value="">
              </div>

              <div class="clear">
                <input class="button" id="mc-embedded-subscribe" name="subscribirse" type="submit" value="Subscribirse">
              </div>
            </form>
          </div>
        </div>
        <!--End mc_embed_signup-->
      </div>
    </div>
  </div>

  <p class="copyright">© Copyright <a href="https://www.bluehosting.cl/" rel="dofollow" target="_blank"
      title="hosting">Hosting</a> HOSTY, todos los derechos reservados.</p>
</footer>
