<!--  PRCING BOXES COMPARISON  -->
<div class="pricingboxes-comparison">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>MANOS A LA OBRA</h2>

      <p i18n>¡Ponemos a tu disposición todo lo que necesitas para empezar tu propia empresa de hosting!</p>
    </div>
  </div>

  <div class="spacing-30"></div>

  <div class="row collapse">
    <div class="small-12 large-3 medium-3 columns wow zoomIn hostingfeatures" data-wow-delay="0.2s">
      <div class="title-features" i18n>CARACTERÍSTICAS</div>

      <ul class="pricing-table alter features">
        <li class="bullet-item" *ngFor="let item of tableCharacteristicsTitles" i18n>{{item}}</li>
      </ul>
    </div>

    <div class="small-12 large-3 medium-3 columns wow zoomIn" data-wow-delay="0.4s" *ngFor="let column of tableCharacteristicsInfo">
      <div class="title-alt" i18n>{{column.title}}</div>

      <ul class="pricing-table alter">
        <li class="bullet-item" *ngFor="let item of column.list; let j = index"><span i18n>{{item}}</span>
          <div class="show-for-small" i18n>{{tableCharacteristicsTitles[j]}}</div>
        </li>
        <li class="price" hidden="true" i18n>OFERTA 30% OFF<br>
          <span>{{symbol}}{{column.price | currencyFormat}} <sub>/ANUAL + IVA</sub></span></li>
          <li class="price">OFERTA 30% OFF<br>
            <span>{{symbol}}{{column.price | currencyFormat}} <sub>/MENSUAL {{taxez}}</sub></span></li>
        <li class="cta-button">
          <p><span><a id="{{column.btn_id}}" href="{{column.link}}" i18n>COMPRAR</a></span></p>
        </li>
      </ul>
    </div>
  </div>
</div>
<!--  END OF PRCING BOXES COMPARISON  -->

<!--  SHARE HOSTING FEATURES  -->
<section class="sharedfeatures-odd wow fadeInUp" [preRender]="false" (deferLoad)="showImage1 = true">
  <div class="row">
    <div class="small-12 large-4 medium-4 columns">
      <div class="circle"></div>
    </div>

    <div class="small-12 large-8 medium-8 columns">
      <h3 class="title2" i18n>TU PROPIO NEGOCIO A BAJO COSTO</h3>

      <p i18n>Si buscas tener tu propio negocio en internet y obtener ingresos rápidamente, entonces el hosting reseller es tu opción. Genera ingresos invirtiendo a bajo costo y con todas las herramientas disponibles para crear tu propia empresa de hosting.</p>
    </div>
  </div>
</section>

<section class="sharedfeatures-even wow fadeInUp">
  <div class="row">
    <div class="small-12 large-8 medium-8 columns">
      <h3 class="title2" i18n>WHM / CPANEL</h3>

      <p i18n>Con WHM y cPanel obtendrás todas las herramientas necesarias para tu empresa de hosting. Ya sea que quieras crear cuentas de correos, variados dominios o administrar tus sitios, tendrás mayor control, simpleza y flexibilidad.</p>
    </div>

    <div class="small-12 large-4 medium-4 columns">
      <div class="circle"></div>
    </div>
  </div>
</section>

<section class="sharedfeatures-odd wow fadeInUp">
  <div class="row">
    <div class="small-12 large-4 medium-4 columns">
      <div class="circle"></div>
    </div>

    <div class="small-12 large-8 medium-8 columns">
      <h3 class="title2" i18n>Site PubliSher</h3>

      <p i18n>Podrás ofrecer a tus clientes la mejor opción para la construcción de sitios web. Ya seas un profesional o alguien que comienza, Site PubliSher presenta variadas opciones para la creación de sitios web de forma definitiva y sin necesidad de recurrir a ninguna aplicación más.</p>
    </div>
  </div>
</section>

<section class="sharedfeatures-even wow fadeInUp">
  <div class="row">
    <div class="small-12 large-8 medium-8 columns">
      <h3 class="title2" i18n>SOFTACULOUS</h3>

      <p i18n>Softaculous ofrece más de 250 aplicaciones / scripts instalables con un solo click. Desde blogs, sistemas de gestión de contenidos como Joomla o Wordpress, autoinstaladores, copias de seguridad e incluso las bibliotecas de Java Script... Una herramienta necesaria para tu empresa de hosting.</p>
    </div>

    <div class="small-12 large-4 medium-4 columns">
      <div class="circle"></div>
    </div>
  </div>
</section>
<!--  SHARE HOSTING FEATURES  -->

<!-- PRODUCTOS ADICIONALES   -->
<!--
<div class="dedicated-servers-features floralwhite">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>PRODUCTOS ADICIONALES</h2>

      <p i18n>Acá podrás ver todos los productos adicionales que te ofrecen los planes VPS.</p>

      <ul class="small-block-grid-1 large-block-grid-2 medium-block-grid-2">
        <li class="wow fadeInLeft" [ngClass]="item.class" data-wow-delay="0.3s" *ngFor="let item of additionalProducts">
          <div class="row">
            <div class="small-12 large-3 medium-3 columns">
              <ng-container *ngIf="showImage1">
                <img alt="" src="/assets/images/web-hosting/{{item.img}}.png">
              </ng-container>

            </div>

            <div class="small-12 large-9 medium-9 columns">
              <h3 i18n>{{item.title}}</h3>

              <h5>{{symbol}}{{item.price | currencyFormat}} {{taxez}}</h5>

              <p i18n>{{item.text}}</p>

              <p i18n>{{item.sub_text}}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
-->
<!-- PRODUCTOS ADICIONALES END   -->
