import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LocaleService } from '../../services/locale.service';
import { DATA_SITE_CL, DATA_SITE_PE, DATA_SITE_CO, DATA_SITE_ME, DATA_SITE_AR } from '../../data-site';
import { URL } from '../../../environments/environment';
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})

export class FooterComponent implements OnInit {
    public URL = URL;
    locale;
    data_footer;
    tld;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private localeService: LocaleService,
    ) {
        this.locale = this.localeService.locale;
        this.tld = this.localeService.TLD != 'ar' ? this.localeService.TLD : 'com.ar';
    }

    ngOnInit() {
        if (this.locale == 'es-CL') {
            this.data_footer = DATA_SITE_CL;
        } else if (this.locale == 'es-PE') {
            this.data_footer = DATA_SITE_PE;
        } else if (this.locale == 'es-CO') {
            this.data_footer = DATA_SITE_CO;
        } else if (this.locale == 'es-MX') {
            this.data_footer = DATA_SITE_ME;
        } else if (this.locale == 'es-AR') {
            this.data_footer = DATA_SITE_AR;
            this.tld = 'com.ar';
        } else {
            this.data_footer = DATA_SITE_CL;
        }
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }
}
