<!--  ORDER STEPS  -->
<div class="vps-order-steps">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>DIFUNDE LA PALABRA</h2>
      <p i18n>Únete al programa de afiliados. Simplemente refiérenos en tu sitio y obtén ganancias cuando tus visitantes
        compren en nuestra página.</p>

      <div class="spacing-top-50"></div>

      <div class="row">
        <div class="large-4 medium-4 columns text-center wow zoomIn hide-for-small-only"
            data-wow-delay="0.2s"
            *ngFor="let item of steps">
          <img src="/assets/images/partners-program/{{item.img}}.png" alt="">
        </div>
        <div class="large-12 columns hide-for-small-only">
          <div class="order-step">
            <div class="row collapse">
              <div data-wow-delay="0.2s" class="order-circle large-4 medium-4 columns wow fadeInUp">
                <div class="line left-side"></div>
                <span class="left-side">1</span>
              </div>
              <div data-wow-delay="0.4s" class="order-circle large-4 medium-4 columns wow fadeInUp">
                <div class="line"></div>
                <span>2</span>
              </div>
              <div data-wow-delay="0.6s" class="order-circle large-4 medium-4 columns wow fadeInUp">
                <div class="line right-side"></div>
                <span class="right-side">3</span>
              </div>
            </div>
          </div>
        </div>
        <div class="large-4 medium-4 small-12 columns text-center" *ngFor="let item of steps">
          <img class="show-for-small-only" src="/assets/images/partners-program/{{item.img}}.png" alt="">
          <h3 i18n>{{item.title}}</h3>
          <p i18n>{{item.text}}</p>
        </div>

        <div class="small-12 columns">
          <div data-wow-delay="0.5s" class="linkcenter wow fadeInUp">
            <a href="https://panel.hosty.host/register.php" class="small radius button" i18n>¡INSCRÍBETE AHORA!</a>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
<!--  END OF ORDER STEPS  -->

<section class="members">

  <div id="faq-content">
    <div class="faq-subheader">
      <div class="row">
        <div class="small-12 columns">

          <!--  AFILIADOS FAQ  -->
          <div class="faqcategory">
            <div class="row">
              <div class="small-12 large-3 medium-3 columns">
                <h1 i18n>PROGRAMA DE AFILIADOS</h1>
              </div>
              <div class="small-12 large-9 medium-9 columns spacing-top-15">
                <ng-container *ngFor="let item of partnersProgram">
                  <div class="question" i18n>{{item.title}}</div>
                  <p></p>
                  <ul class="white">
                    <li *ngFor="let point of item.list" i18n>{{point}}</li>
                  </ul>
                  <p></p>
                </ng-container>

              </div>
            </div>
          </div>
          <!--  AFILIADOS FAQ  END -->
        </div>
      </div>
    </div>
  </div>

</section>
