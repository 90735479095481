<!-- START PRICING BOXES  -->
<div #pricingboxes class="pricingboxes">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>MAYOR PODER, CAPACIDAD Y FLEXIBILIDAD</h2>

      <p i18n>VPS consiste en dividir un servidor físico en varios servidores dedicados lógicos. Aunque comparten el mismo hardware están totalmente aislados. Cada VPS funciona y ejecuta las aplicaciones exactamente igual que un servidor físico, tiene su propio sistema operativo, acceso a root, usuarios, direcciones IP, memoria, procesos, bibliotecas de sistema y archivos de configuración propios e independientes.</p>
    </div>
  </div>

  <div class="spacing-30"></div>

  <div class="row">
    <div class="small-12 large-4 medium-4 columns wow" [ngClass]="plan.class" data-wow-delay="0.2s" *ngFor="let plan of dataPlans">
      <div class="title" i18n>{{plan.title}}</div>

      <ul class="pricing-table">
        <li class="description" [innerHtml]="plan.card['desc']"></li>
        <li class="bullet-item" *ngFor="let item of plan.card['list']" i18n>{{item}}</li>
        <li class="price" i18n>OFERTA 30% OFF<br>
          <span>{{symbol}}{{plan.price | currencyFormat}}</span>MENSUAL + IVA</li>
        <li class="cta-button">
          <p><span><a [id]="plan.btn_id" href="{{plan.link}}" i18n>COMPRAR</a></span></p>
        </li>
      </ul>
    </div>
  </div>
</div>
<!--  END OF PRCING BOXES  -->

<!-- PREMIUM SERVERS - TABLES   -->
<div class="features premium-servers" [preRender]="false" (deferLoad)="showImage1 = true">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>NUESTROS PLANES EN DETALLE</h2>

      <p i18n>Te invitamos a revisar todos nuestros planes VPS en profundidad, para que escogas el que más se acomoda a lo
        que tus proyectos necesitan.</p>

      <table class="flat-table flat-table-1 responsive wow fadeInUp tablesaw tablesaw-stack" data-mode="stack"
        data-wow-delay="0.3s">
        <thead>
          <tr>
            <th *ngFor="let cell of detailPlansHead" i18n>{{cell}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of detailPlansRow">
            <td *ngFor="let cell of row.cell" i18n>{{cell}}</td>
            <td>{{symbol}}{{row.price | currencyFormat}}xmes</td>
            <td>
              <span><a id="{{row.btn_id}}" href="{{row.link}}" i18n>COMPRAR</a></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <p class="vps_taxes" > Los valores indicados no incluyen IMPUESTOS* </p>
</div>
<!-- END OF PREMIUM SERVERS - RESPONSIVE TABLES   -->

<!-- DEDICATED SERVER FEATURES   -->
<div class="dedicated-servers-features">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>CARACTERÍSTICAS VPS</h2>

      <p i18n>Acá podrás ver algunas de las características y detalles que te ofrecen los planes VPS.</p>

      <ul class="small-block-grid-1 large-block-grid-2 medium-block-grid-2">
        <li class="wow" [ngClass]="item.class" data-wow-delay="0.3s" *ngFor="let item of featuresVps">
          <div class="row">
            <div class="small-12 large-3 medium-3 columns">
              <ng-container *ngIf="showImage1"><!-- showImage1 because we need render before. -->
                <img alt="" src="/assets/images/vps/{{item.img}}.png">
              </ng-container>
            </div>

            <div class="small-12 large-9 medium-9 columns">
              <h3 i18n>{{item.title}}</h3>

              <p i18n>{{item.text}}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- DEDICATED SERVER FEATURES   -->

<!-- PRODUCTOS ADICIONALES   -->
<div class="dedicated-servers-features floralwhite">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>PRODUCTOS ADICIONALES</h2>

      <p i18n>Acá podrás ver todos los productos adicionales que te ofrecen los planes VPS.</p>

      <ul class="small-block-grid-1 large-block-grid-2 medium-block-grid-2">
        <li class="wow" [ngClass]="item.class" data-wow-delay="0.3s" *ngFor="let item of additionalProducts">
          <div class="row">
            <div class="small-12 large-3 medium-3 columns">
              <ng-container *ngIf="showImage1"><!-- showImage1 because we need render before. -->
                <img alt="" src="/assets/images/vps/{{item.img}}.png">
              </ng-container>

            </div>

            <div class="small-12 large-9 medium-9 columns">
              <h3 i18n>{{item.title}}</h3>

              <h5>{{symbol}}{{item.price | currencyFormat}} {{taxez}} </h5>

              <p i18n>{{item.text}}</p>

              <p i18n>{{item.sub_text}}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- PRODUCTOS ADICIONALES END   -->
