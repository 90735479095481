import { Component, OnInit } from '@angular/core';
import { MetaService } from '@ngx-meta/core';
import { LocaleService } from '../services/locale.service';

@Component({
    selector: 'app-logo-center',
    templateUrl: './logo-center.component.html'
})

export class LogoCenterComponent implements OnInit {
    logos;

    constructor(private readonly _meta: MetaService,
        private localeService: LocaleService) {
    }

    ngOnInit() {
        this.logos = this.localeService.content.LOGO_CENTER.logos;
        this._setMeta();
    }

    private _setMeta() {
        this._meta.setTitle('Centro de Logotipos - Hosty | Web Hosting Chile |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Acá están variados banners de Web Hosting Hosty, para que compartas en tu sitio nuestros distintos planes y ventajas a tus amigos, conocidos o clientes.');
        this._meta.setTag('keywords', '');
    }

}
