import { Component, OnInit, EventEmitter, Inject, Output, PLATFORM_ID, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { SearchService } from '../../../services/search.service';
import { IInvoiceResponse } from '../../../interfaces/invoice.interface';

import { ReCaptchaV3Service } from 'ngx-captcha';
import { isPlatformBrowser } from '@angular/common';
import { UrlService } from '../../../services/url.service';

import { dP_emailRegex, dP_invoiceRegex, dP_tldRegex } from '../../../../../../common/functions/regex-standarts';
@Component({
    selector: 'app-search-form',
    templateUrl: './search-form.component.html',
    styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {
    public parameters;
    public form: FormGroup;
    public isLoading = false;
    private siteKey: string = '6LeFGJMUAAAAAEDLJLme8uOEGMOTwsjYB6v-gZe7';
    private captcha: any;
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private formBuilder: FormBuilder,
        private renderer: Renderer2,
        private apiService: ApiService,
        private searchService: SearchService,
        private reCaptchaService: ReCaptchaV3Service,
        private urlService: UrlService
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            search: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(63)]]
        });
        this.parameters = { query: this.urlService.getValue('query') };
        if (!!this.parameters.query) {
            this.form.controls['search'].setValue(this.parameters['query']);
            this.form.markAllAsTouched();
            this.form.markAsDirty()
            this.form.markAsTouched();
            this.onSubmit();
        }
    }

    public onSubmit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.startCaptcha(true)
        }
        this.isLoading = true;
        this.searchService.setIsLoading(this.isLoading);
        this._setOverlay();
        let invoice = '';
        let email = '';
        let domain = '';
        const input = this.form.controls['search'].value;
        this.searchService.setComplete({ show: false, status: '', data: {} });
        this.searchService.setShowNoResult({ show: false, status: '', data: {} });
        if (dP_emailRegex.test(input)) {
            email = input;
        } else if (dP_invoiceRegex.test(input)) {
            invoice = input;
        } else if (dP_tldRegex.test(input)) {
            domain = input;
        } else {
            this.searchService.setShowNoResult({ show: true, status: 'badInput', data: {} });
            this.isLoading = false;
            this.searchService.setIsLoading(this.isLoading);
            this._setOverlay();
            return;
        }
        const formData = new FormData();
        formData.append("invoice", invoice);
        formData.append("email", email);
        formData.append("domain", domain);

        this.apiService.findInvoice(formData).subscribe(res => {
            let values: any = Object.values(res);
            const data: IInvoiceResponse = { show: true, status: values[0], data: values[1] };
            if (values[0] === 'No found' || values[0] === 'error') {
                this.searchService.setShowNoResult(data);
            }
            if (values[0] === 'Found finished') {
                this.searchService.setComplete(data)
            }
            if (values[0] === 'found') {
                this.searchService.setComplete(data)
            }
        }, err => {
            console.error(err);
            this.isLoading = false;
            this._setOverlay();
            this.searchService.setIsLoading(this.isLoading);
        }, () => {
            this.isLoading = false;
            this._setOverlay();
            this.searchService.setIsLoading(this.isLoading);
        })

    }
    private _setOverlay(): void {
        if (this.isLoading) {
            this.renderer.addClass(document.body.parentElement, 'overflow-hidden');
        } else {
            this.renderer.removeClass(document.body.parentElement, 'overflow-hidden')
        }
    }
    private startCaptcha(state: boolean): void {
        this.reCaptchaService.execute(this.siteKey,
            'dialog_tariff',
            (token) => {
                if (state) {
                    this.captcha = token;
                    const element = document.getElementsByClassName('grecaptcha-badge')[0];
                    element.setAttribute('style', element.getAttribute('style') + 'bottom: 108px;');
                }
            });
    }
}
