<!-- INTRO TERMINOS Y CONDICIONES   -->
<div class="features servers">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>AYÚDANOS A COMPARTIR</h2>
      <p i18n>Acá están variados banners para que compartas en tu sitio nuestros distintos planes y ventajas a tus amigos, conocidos o clientes.</p>
    </div>
  </div>
</div>

<!--  MAIN PAGE CONTENT  -->
<div id="faq-content">
  <div class="faq-subheader">
    <div class="row">
      <div class="small-12 columns">

        <!--  BANNERS  -->
        <div class="faqcategory" *ngFor="let item of logos">
          <div class="row">
            <div class="small-12 large-3 medium-3 columns">
              <h3 class="title1" i18n>{{item.title}}</h3>
            </div>
            <div class="small-12 large-9 medium-9 columns spacing-top-15">

              <ul class="small-block-grid-1" [ngClass]="item.class">
                <li *ngFor="let img of item.images">
                  <img src="/assets/images/logo-center/{{img}}.png" alt="">
                </li>
              </ul>

              <p i18n>
                <ng-container *ngFor="let text of item.info; let i = index">
                  {{text}}: | <a href="https://www.hosty.cl/assets/images/logo-center/{{item.images[i]}}.png.zip">DESCARGA</a>
                  <br>
                </ng-container>
              </p>

            </div>
          </div>
        </div>
        <!--  BANNERS  END -->

      </div>
    </div>
  </div>
</div>
