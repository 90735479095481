<!--  START OF FEATURES -->
<section class="features">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>CONOCE NUESTRAS CARACTERÍSTICAS</h2>

      <p i18n>Tenemos variadas opciones para todos tus proyectos e ideas. Te invitamos a descubrir todos los beneficios y ventajas que tenemos para tí.</p>

      <ul class="small-block-grid-1 large-block-grid-3 medium-block-grid-3">
        <li class="wow fadeInLeft animated" data-wow-delay="0.2s">
          <h3 i18n>Plataforma Cloud</h3>

          <p i18n>Trabaja de manera más veloz y eficiente con nuestra infraestructura Cloud y olvídate de problemas de
            seguridad y estabilidad.</p>
        </li>
        <li class="wow fadeInLeft animated" data-wow-delay="0.4s">
          <h3 i18n>cPanel</h3>

          <p i18n>Gestiona tus datos de manera más rápida y sencilla con una de las principales herramientas de
            administración web.</p>
        </li>
        <li class="wow fadeInLeft animated" data-wow-delay="0.6s">
          <h3 i18n>Herramientas para desarrolladores</h3>

          <p i18n>Tendrás a tu disposición un autoinstalador con más de 200 scripts, listos para facilitar tus sitios y
            proyectos.</p>
        </li>
        <li class="wow fadeInRight animated" data-wow-delay="0.2s">
          <h3 i18n>AntiSpam</h3>

          <p i18n>Despreocúpate de recibir ese molesto spam en tu bandeja de entrada. Ahora podrás bloquear hasta el 80% de Spam que recibas con Anti-Spam ASSP.</p>
        </li>
        <li class="wow fadeInRight animated" data-wow-delay="0.4s">
          <h3 i18n>Firewall</h3>

          <p i18n>Protegemos todos tus archivos con análisis de seguridad actualizadas semanalmente de AtomiCorp GotRoot
            ModSecurity Rules.</p>
        </li>
        <li class="wow fadeInRight animated" data-wow-delay="0.6s">
          <h3 i18n>Respaldo Automático</h3>

          <p i18n>Realizamos un respaldo diario, semanal y mensual de tus datos, por lo que puedes restaurar o modificar tus archivos con mayor tranquilidad.</p>
        </li>
      </ul>
    </div>
  </div>
</section>
<!--  END OF FEATURES -->

<!--  CALL TO ACTION  -->
 <section #percentAnimateTrigger1 class="calltoaction" [preRender]="false" (deferLoad)="showImage1 = true">
 <div class="row">
    <div class="small-12 columns">
      <div class="longshadow wow fadeInDown animated" data-wow-delay="0.3s" i18n>¿CLIENTE NUEVO?</div>

      <div class="calltoactioninfo wow fadeInUp animated" data-wow-delay="0.5s">
        <h2 i18n><span id="discount">{{curPerc}}</span><span>%</span> DTO. POR AÑO</h2>

        <h3 i18n>USA EL CÓDIGO <strong>"HOSTY30"</strong></h3>
        <a id="h-aprovechar" class="small radius button" [routerLink]="['/web-hosting']" (click)="scrollTop()" i18n>¡APROVECHA AHORA!</a>
      </div>
    </div>
  </div>
</section>
<!--  END OF CALL TO ACTION -->

<!-- START PRICING BOXES  -->
<div #pricingboxes class="pricingboxes">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>ENCUENTRA EL MEJOR PLAN PARA TÍ</h2>

      <p i18n>Tenemos variadas opciones acorde a tus necesidades. Desde un pequeño blog hasta sitios de e-commerce con altas visitas, acá encontrarás todas las herramientas para comenzar tus proyectos.</p>
    </div>
  </div>

  <div class="spacing-30"></div>

  <div class="row">
    <div class="small-12 large-4 medium-4 columns wow zoomIn" data-wow-delay="0.2s" *ngFor="let plan of dataPlans">
      <div class="title" i18n>{{plan.title}}</div>

      <ul class="pricing-table">
        <li class="description" [innerHtml]="plan.card['desc']"></li>
        <li class="bullet-item" *ngFor="let item of plan.card['list']" i18n [innerHtml]="item"></li>
        <li *ngIf="plan.title != 'VPS'" class="price" i18n>OFERTA 30% OFF<br>
          <span>{{symbol}}{{plan.price | currencyFormat}}</span>ANUALES + IVA</li>
        <li *ngIf="plan.title == 'VPS'" class="price">OFERTA 30% OFF<br>
            <span>{{symbol}}{{plan.price | currencyFormat}}</span>MENSUALES  {{taxez}}</li>
        <li class="cta-button">
          <p><span><a [id]="plan.btn_id" [routerLink]="plan.route" (click)="scrollTop()" i18n>VER PLANES</a></span></p>
        </li>
      </ul>
    </div>
  </div>
</div>
<!--  END OF PRCING BOXES  -->

<!-- START OF TESTIMONIALS  -->
<section #lovedbyAnimationTrigger class="testimonials">
  <div class="row">
    <div class="small-12 columns">
      <div class="circle"></div>

      <h2 i18n>MAS DE <span id="lovedby">{{curLovedby}}</span> CLIENTES NOS AVALAN</h2>

      <hr class="small">

      <owl-carousel-o [options]="customOptions">

        <ng-container *ngFor="let slide of slidesStore">
          <ng-template carouselSlide [id]="slide.id">
            <div class="item">
              <div class="whoclient" i18n><span>{{slide.name}}</span></div>

              <div class="testimonial-content">
                <div class="testimonialimg">
                  <ng-container *ngIf="showImage1"><!-- showImage1 because we need render before. -->
                    <img alt="" src="/assets/images/user.png">
                  </ng-container>

                </div>

                <p i18n>{{slide.text}}</p>
              </div>
            </div>
          </ng-template>
        </ng-container>

      </owl-carousel-o>
    </div>
  </div>
</section>
<!--  END OF TESTIMONIALS -->

<!-- START MONITORING  -->
<section class="monitoring" [class.show-image]="showImage1"><!-- showImage1 because we need render before. -->
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>MONITOREO EN TIEMPO REAL</h2>

      <p class="text-center" i18n>Llevamos un control constante de nuestros sistemas para darte el mejor servicio, seguridad y tranquilidad.</p>

      <div class="text-center wow fadeInUp animated" data-wow-delay="0.3s">
        <ng-container *ngIf="showImage1"><!-- showImage1 because we need render before. -->
          <img src="/assets/images/monitoring.png" alt="">
        </ng-container>
      </div>
    </div>
  </div>
</section>
<!--  END OF MONITORING  -->
