<header class="alt-8">
  <div class="top">
    <div class="row">
      <div class="small-12 large-3 medium-3 columns">
        <div class="logo">
          <a [routerLink]="['/']"><img alt="" src="/assets/images/logo.png"></a>
        </div>
      </div>
    </div>
  </div>
  <!--  MESSAGES ABOVE HEADER IMAGE -->

  <div class="message">
    <div class="row">
      <div class="small-12 columns">
        <div class="message-intro">
          <div class="title" i18n>¡PROMO HOSTY!</div>
        </div>

        <h1 class="promotitle" i18n>¡TEN 10 DÍAS GRATIS CON NOSOTROS!</h1>
      </div>
    </div>
  </div>
  <!--  END OF MESSAGES ABOVE HEADER IMAGE -->
</header>

<div class="row collapse supportchannels">
  <div class="small-12 large-4 large-offset-1 medium-4 medium-offset-1 columns"
    style="height: 370px;">
    <div class="timeline-content wow fadeInLeft" data-wow-delay="0.3s">
      <h2 class="title2" i18n>OPCIÓN 1<br> 10 DÍAS GRATIS</h2>

      <p i18n>Utilizaré por 10 días el servicio gratuito. Al término de este período pagaré la factura generada por el
        sistema desde mi área cliente.</p>
      <a class="small radius ticket button"
        href="{{link_option_1}}" i18n>SOLICITAR AHORA</a>
    </div>
  </div>

  <div class="large-1 large-offset-1 medium-1 medium-offset-1 hide-for-small line columns" style="height: 370px;">
    <div class="roundimg wow fadeInUp"><img alt="icon support" src="/assets/images/support_icon_1.png"></div>
  </div>

  <div class="small-12 large-4 large-offset-1 medium-4 medium-offset-1 columns"
    style="height: 370px;">
    <div class="timeline-content wow fadeInRight" data-wow-delay="0.3s">
      <h2 class="title2" i18n>OPCIÓN 2<br> COMPRAR DIRECTAMENTE</h2>

      <p i18ni18n>Pagaré directamente la factura generada por el servicio ahora mismo, sin necesidad de esperar los 10 días para
        pagar el servicio.</p>
      <a class="small radius chat button"
        href="{{link_option_2}}" i18n>COMPRAR AHORA</a>
    </div>
  </div>
</div>
