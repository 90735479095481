<!-- INTRO TERMINOS Y CONDICIONES   -->
<div class="features servers">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>AUSPICIO HOSTING</h2>
      <p i18n>HOSTY busca apoyar y fomentar de manera activa a los emprendedores. Por lo mismo, contamos con nuestro programa de auspicio, que busca patrocinar a cualquier tipo de sitio web que cumpla con los requerimientos. El proceso para aplicar a este beneficio es sencillo y no toma mucho tiempo.</p>
    </div>
  </div>
</div>

<!--  MAIN PAGE CONTENT  -->
<div id="faq-content">
  <div class="faq-subheader">
    <div class="row">
      <div class="small-12 columns">

        <!-- CATEGORY FAQ  -->
        <div class="faqcategory" *ngFor="let item of faqcategory">
          <div class="row">
            <div class="small-12 large-3 medium-3 columns">
              <h3 class="title1" i18n>{{item.title}}</h3>
            </div>
            <div class="small-12 large-9 medium-9 columns spacing-top-15">
              <p i18n>{{item.text}}</p>
            </div>
          </div>
        </div>
        <!-- CATEGORY FAQ  END -->

      </div>
    </div>
  </div>
</div>

<!--  CONTACT CONTENT  -->
<div class="members">
  <div class="row">
    <div class="small-12 large-4 medium-4 columns">
      <h4 class="orange" i18n>PROCESO DE APLICACIÓN</h4>
      <ul class="white">
        <li i18n>1. Complete esta sencilla Aplicación.</li>
        <li i18n>2. Revisaremos su Aplicación.</li>
        <li i18n>3. Nos contactaremos con usted.</li>
      </ul>

      <h4 class="orange" i18n>BENEFICIOS</h4>
      <ul class="white">
        <li i18n>1. Hospedaje Gratuito.</li>
        <li i18n>2. Patrocinio hasta un Servidor Dedicado.</li>
      </ul>

    </div>

    <div class="small-12 large-8 medium-8 columns">

      <div id="contactform">
        <form [formGroup]="form">
          <div class="sendstatus" *ngIf="!form.valid">
            <div class="alert-box alert" *ngIf="submitted && !form.get('name').valid">Please enter your name</div>
            <div class="alert-box alert" *ngIf="submitted && !form.get('email').valid">Please enter a valid email address</div>
          </div>
          <div class="sendstatus" *ngIf="form.valid && isNameValid">
            <div class="alert-box success" *ngIf="submitted">
              Thanks {{userName}}. Your message has been sent.
              <a class="close" (click)="refresh()">×</a>
            </div>
          </div>

          <p>
            <label for="name" class="orange" i18n>Nombre:*</label>
            <input type="text" name="name" id="name" tabindex="1" formControlName="name" required>
          </p>
          <p>
            <label for="email" class="orange" i18n>Email:*</label>
            <input type="text" name="email" id="email" tabindex="2" formControlName="email" required>
          </p>
          <p>
            <label for="phone" class="orange" i18n>Teléfono:</label>
            <input type="text" name="phone" id="phone" tabindex="3">
          </p>
          <p>
            <label for="url" class="orange" i18n>URL sitio web:*</label>
            <input type="text" name="url" id="url" tabindex="4">
            </p>
          <p>
            <label for="rank" class="orange" i18n>PageRank Sitio Web:*</label>
            <input type="text" name="rank" id="rank" tabindex="5">
          </p>
          <p>
            <input type="button" name="submit" id="submit" class="button radius medium" value="Submit" (click)="onSubmit()">
          </p>

        </form>
      </div>

    </div>
  </div>
</div>
<!--  END OF CONTACT CONTENT  -->
