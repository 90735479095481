import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MetaService } from '@ngx-meta/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { URL } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-sponsorship-program',
    templateUrl: './sponsorship-program.component.html'
})

export class SponsorshipProgramComponent implements OnInit {
    URL = URL;
    locale;
    faqcategory
    // form
    form: FormGroup;
    submitted = false;
    userName: string;
    isNameValid: boolean = false;

    constructor(
        private router: Router,
        private wowService: NgwWowService,
        private readonly _meta: MetaService,
        private formBuilder: FormBuilder,
        private localeService: LocaleService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        this.locale = this.localeService.locale;

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(event => {
            // Reload WoW animations when done navigating to page,
            if (isPlatformBrowser(this.platformId)) {
                this.wowService.init();
            }
        });
    }

    ngOnInit() {
        this.faqcategory = this.localeService.content.SPONSORSHIP.faqcategory;
        // Set Meta Tags
        this.faqcategory = this.localeService.content.SPONSORSHIP.faqcategory;
        if (this.locale == 'es-CL') {
            this._setMetaCL();
        } else if (this.locale == 'es-PE') {
            this._setMetaPE();
        } else if (this.locale == 'es-CO') {
            this._setMetaCO();
        } else if (this.locale == 'es-MX') {
            this._setMetaME();
        } else if (this.locale == 'es-AR') {
            this._setMetaAR();
        } else {
            this._setMetaCL();
        }

        this.form = this.formBuilder.group({
            name: ['', [
                Validators.required
            ]],
            email: ['', [
                Validators.required,
                Validators.email
            ]]
        });
    }

    onSubmit() {
        this.submitted = true;

        if (this.form.invalid) {
            return;
        }

        this.isNameValid = true;
        this.userName = this.form.get('name').value;
    }

    refresh() {
        window.location.reload();
    }

    private _setMetaCL() {
        this._meta.setTitle('Programa de Auspicio - Hosty | Web Hosting Chile |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'HOSTY Web Hosting busca apoyar y fomentar de manera activa a los emprendedores con su programa de auspicio, que patrocina a cualquier tipo de sitio web que cumpla con los requerimientos.');
        this._meta.setTag('keywords', '');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Programa de Auspicio - Hosty | Web Hosting Chile |');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaPE() {
        this._meta.setTitle('Programa de Auspicio - Hosty | Web Hosting Perú |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'HOSTY Web Hosting busca apoyar y fomentar de manera activa a los emprendedores con su programa de auspicio, que patrocina a cualquier tipo de sitio web que cumpla con los requerimientos.');
        this._meta.setTag('keywords', '');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Programa de Auspicio - Hosty | Web Hosting Perú |');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaCO() {
        this._meta.setTitle('Programa de Auspicio - Hosty | Web Hosting Colombia |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'HOSTY Web Hosting busca apoyar y fomentar de manera activa a los emprendedores con su programa de auspicio, que patrocina a cualquier tipo de sitio web que cumpla con los requerimientos.');
        this._meta.setTag('keywords', '');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Programa de Auspicio - Hosty | Web Hosting Colombia |');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaME() {
        this._meta.setTitle('Programa de Auspicio - Hosty | Web Hosting México |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'HOSTY Web Hosting busca apoyar y fomentar de manera activa a los emprendedores con su programa de auspicio, que patrocina a cualquier tipo de sitio web que cumpla con los requerimientos.');
        this._meta.setTag('keywords', '');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Programa de Auspicio - Hosty | Web Hosting México |');
        this._meta.setTag('og:locale', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }
    private _setMetaAR() {
        this._meta.setTitle('Programa de Auspicio - Hosty | Web Hosting Argentina |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'HOSTY Web Hosting busca apoyar y fomentar de manera activa a los emprendedores con su programa de auspicio, que patrocina a cualquier tipo de sitio web que cumpla con los requerimientos.');
        this._meta.setTag('keywords', '');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Programa de Auspicio - Hosty | Web Hosting Argentina |');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }

}
