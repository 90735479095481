import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID, ElementRef } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { ArrHeader, DATA_SITE_CL, DATA_SITE_PE, DATA_SITE_CO, DATA_SITE_ME, DATA_SITE_AR } from '../../data-site';
import { ScrollToClass } from '../../../../../common/scrollTo.class';
import { LocaleService } from '../../services/locale.service';
import { DomainService } from '../../services/domain.service';
import { FormBuilder, Validators } from '@angular/forms';
import { URL } from '../../../environments/environment';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent extends ScrollToClass implements OnInit, AfterViewInit {
    public URL = URL;
    locale;
    data_header;
    tld;
    tlds: string[] = [];
    form;
    isShowText: boolean = true;
    isShowDropdown: boolean = false;
    isOpenMobileMenu: boolean = false;
    title = [
        'UPTIME 99%',
        'SOPORTE TÉCNICO',
        'EL PLAN DE HOSTING QUE NECESISTAS, AL MENOR PRECIO DEL MERCADO.',
        'QUE DESPEGUEN SUS PLANES',
        'CON NUESTRAS PROMOCIONES',
        'MIGRACIÓN GRATUITA',
    ];

    titleInfo: string;

    constructor(
        public router: Router,
        private wowService: NgwWowService,
        @Inject(PLATFORM_ID) private platformId: any,
        private _el: ElementRef,
        private fb: FormBuilder,
        private localeService: LocaleService,
        private domainService: DomainService
    ) {
        super(_el);
        this.locale = this.localeService.locale;
        this.tld = this.localeService.TLD != 'ar' ? this.localeService.TLD : 'com.ar';
        this.tlds = this.localeService.content.tdls;
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(event => {
            // Reload WoW animations when done navigating to page,
            if (isPlatformBrowser(this.platformId)) {
                this.wowService.init();
            }
        });
        this.form = this.fb.group({
            text: ['', [
                Validators.required,
                Validators.pattern('[ña-zÑA-Z]{3,}')
            ]],
        });
    }

    ngOnInit() {
        if (this.locale == 'es-CL') {
            this.data_header = DATA_SITE_CL;
        } else if (this.locale == 'es-PE') {
            this.data_header = DATA_SITE_PE;
        } else if (this.locale == 'es-CO') {
            this.data_header = DATA_SITE_CO;
        } else if (this.locale == 'es-ME') {
            this.data_header = DATA_SITE_ME;
        } else if (this.locale == 'es-AR') {
            this.data_header = DATA_SITE_AR;
        } else {
            this.data_header = DATA_SITE_CL;
        }

        this.titleInfo = ArrHeader['default'].message_text[0];
    }

    ngAfterViewInit() {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                this.replaceText();
            }, 1000);
        }
    }

    getDataHeader() {
        if (this.router.url === '/web-hosting/.') {
            return ArrHeader['web_hosting'];
        } if (this.router.url === '/web-hosting-reseller/.') {
            return ArrHeader['reseller'];
        } if (this.router.url === '/web-hosting-pyme/.') {
            return ArrHeader['pyme'];
        } if (this.router.url === '/cloud-vps/.') {
            return ArrHeader['vps'];
        } if (this.router.url === '/cloud-vps-backup/.') {
            return ArrHeader['vps_backup'];
        } if (this.router.url === '/programa-auspicio/.') {
            return ArrHeader['programa_auspicio'];
        } if (this.router.url === '/programa-afiliados/.') {
            return ArrHeader['programa_afiliados'];
        } if (this.router.url === '/formas-de-pago/.') {
            return ArrHeader['formas_de_pago'];
        } if (this.router.url === '/centro-de-logotipos/.') {
            return ArrHeader['centro_de_logotipos'];
        } if (this.router.url === '/blog/.') {
            return ArrHeader['blog'];
        } if (this.router.url === '/contacto/.') {
            return ArrHeader['contacto'];
        } else if (this.router.url === '/dominios/.') {
            return ArrHeader['dominios'];
        } else if (this.router.url === '/promo/.') {
            return ArrHeader['promo'];
        } else if (this.router.url.startsWith('/pago-directo/')) {
            return ArrHeader['direct_payment'];
        } else {
            return ArrHeader['default'];
        }
    }

    replaceText() {
        let count = 0;

        setInterval(() => {
            this.isShowText = false;

            setTimeout(() => {
                if (count < 5) {
                    count++;
                    this.isShowText = true;
                    this.titleInfo = this.title[count];
                } else {
                    count = 0;
                    this.isShowText = true;
                    this.titleInfo = this.title[count];
                }
            }, 0);
        }, 6000);
    }

    // HOVER MENU
    addClassHover(el) {
        if (isPlatformBrowser(this.platformId)) {
            if (window.innerWidth > 767) {
                if (el.target.tagName === 'LI' && el.target.classList.contains('dropdown')) {
                    if (!el.target.classList.contains('sfHover')) {
                        el.target.classList.add('sfHover');
                    }
                } else if ((el.target.tagName === 'A')) {
                    if (!el.target.parentElement.classList.contains('sfHover') && el.target.classList.contains('dropdown')) {
                        el.target.parentElement.classList.add('sfHover');
                    }
                }
            }
        }
    }

    removeClassHover(el) {
        setTimeout(() => {
            if (el.target.tagName === 'LI' && el.target.classList.contains('dropdown')) {
                if (el.target.classList.contains('sfHover')) {
                    el.target.classList.remove('sfHover');
                }
            }
        }, 800);
    }

    navigateToSection(className) {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 200);
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    toggleDropdownOnMobile(el) {
        if (isPlatformBrowser(this.platformId)) {
            if (window.innerWidth <= 767) {
                if (el.target.classList.contains('dropdown-mobile')) {
                    el.target.classList.remove('dropdown-mobile');
                } else {
                    el.target.classList.add('dropdown-mobile');
                }
            }
        }
    }

    toggleMobileMenu() {
        if (isPlatformBrowser(this.platformId)) {
            if (window.innerWidth <= 767) {
                this.isOpenMobileMenu = !this.isOpenMobileMenu;
            }
        }
    }

    closeMenu() {
        this.isOpenMobileMenu = false;
        this.scrollTop();
    }

    onSubmit() {
        const searchDomain = this.form.value.text;
        this.domainService.setdomain(searchDomain + (<HTMLSelectElement>document.getElementById('tld')).value);
    }

}
