<div class="sectionNoResult">
  <div class="title1">
    <svg-icon-sprite
      [src]="data.icon"
      [width]="'33px'"
      [class.text-accent]="data.icon === 'icon-sprite-close-circle'">
    </svg-icon-sprite>
    <b [innerHTML]="data.title" i18n></b>
  </div>
  <ng-template *ngIf="data.type=='notRegister'; then notRegister; else noResult"></ng-template>
  <ng-template #notRegister>
    <div class="sectionNoResult__text" [innerHTML]="data.text" i18n></div>
  </ng-template>
  <ng-template #noResult>
    <div class="sectionNoResult__text"i18n>
      {{data.text}}
      <a [routerLink]="['/contacto/.']">
        <b>Contactar con soporte</b>
      </a>
    </div>
  </ng-template>
</div>
