import { Component, Inject, OnInit, Renderer2, PLATFORM_ID , Injectable} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { INoResult } from './no-search-result/no-search-result.component';
import { LocaleService } from '../services/locale.service';
import { MetaService } from '@ngx-meta/core';
import { URL } from '../../environments/environment';
import { SearchService } from '../services/search.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-direct-payment',
    templateUrl: './direct-payment.component.html',
    styleUrls: ['./direct-payment.component.scss']
})
@Injectable({
    providedIn: 'root'
    })
export class DirectPaymentComponent implements OnInit {

    public URL = URL;

    public isLoading: boolean;
    public isNoResult: boolean;
    public isComplete: boolean;

    public dataNoResult;
    public dataResult;

    public notRegister: { "error": INoResult, "badInput": INoResult } = {
        "error": {
            icon: "icon-sprite-close-circle",
            title: '<b>No se encontraron</b> resultados<span class="accent-primary">.</span>',
            text: '<b>Lo sentimos!</b> no se encontraron resultados, por favor vuelve a intentarlo más tarde.',
            state: 'error',
            type: 'notRegister'
        },
        "badInput": {
            icon: "icon-sprite-close-circle",
            title: '<b>No se encontraron</b> resultados<span class="accent-primary">.</span>',
            text: 'Los datos ingresados no corresponden a un Dominio, un Correo electrónico o un N° de Orden. Por favor verifica la información ingresada.',
            state: 'error',
            type: 'notRegister'
        }
    }

    public noResult: INoResult = {
        icon: 'icon-sprite-check-circle',
        title: '<b>Cliente</b> no registra deuda<span class="accent-primary">.</span>',
        text: "Si necesitas más información o requieres resolver dudas técnicas o comerciales visita:",
        type: 'noResult'
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private localeService: LocaleService,
        private router: Router,
        private readonly _meta: MetaService,
        private searchSrv: SearchService,
        private renderer: Renderer2
    ) { }

    ngOnInit(): void {
        this.searchSrv.getIsLoading$().subscribe(x => {
            this.getLoadingStatus(x);
        });

        this.searchSrv.getShowNoResult$().subscribe(x => {
            this.getNoResultVisibility(x);
        });

        this.searchSrv.getComplete$().subscribe(x=> {
            this.getCompleteStatus(x);
        })
        
        this._scrollTop();
        this._setMeta();
    }

    private _setMeta() {
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + 'assets/images/og/og-pago-directo.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
    }

    private _setOverlay(): void {
        if (this.isLoading) {
            this.renderer.addClass(document.body.parentElement, 'overflow-hidden');
        } else {
            this.renderer.removeClass(document.body.parentElement, 'overflow-hidden')
        }
    }
    public getLoadingStatus(ev): void {
        this.isLoading = ev;
    }

    public getNoResultVisibility(ev): void {
        this.isNoResult = ev.show;
        if (ev.status === 'No found') {
            this.dataNoResult = this.noResult;
        } else {
            this.dataNoResult = this.notRegister[ev.status];
        }
    }

    public getCompleteStatus(ev): void {
        this.isComplete = ev.show;
        this.dataResult = ev;
    }
    private _scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

}
