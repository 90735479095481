import { Component, OnInit, HostListener } from '@angular/core';
import { MetaService } from '@ngx-meta/core';
import { LocaleService } from '../services/locale.service';
import { URL } from '../../environments/environment';
import { DomainService } from '../services/domain.service';
import { domain_Rec } from '../data-domain';
import { DOMAINS_PRICE_CL } from '../content/content-cl';
import { DOMAINS_PRICE_PE } from '../content/content-pe';
import { DOMAINS_PRICE_CO } from '../content/content-co';
import { DOMAINS_PRICE_ME } from '../content/content-me';
import { DOMAINS_PRICE_AR } from '../content/content-ar';

@Component({
    selector: 'app-domain',
    templateUrl: './domain.component.html'
})

export class DomainComponent implements OnInit {
    URL = URL;
    locale;
    tlds;
    tld;
    symbol = '';
    btnlink = 'https://panel.hosty.host/cart.php?a=add&domain=register&currency=1';
    taxes: string;
    searchDomain = '';
    currencyIndexPeriod = 0;
    selctedIndexPeriod = 0;
    isAvailable: boolean;
    isLoading: boolean = true;
    dataRecommendZone : domain_Rec [] = [] ;
    isMobile = false;
    searchDomainlink : string ;
    domain_table;
    tableDomainesPriceTitles;
    tableDomainesInfo;
    periods: any;

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isMobile = window.innerWidth < 767 ? true : false;
    }

    constructor(
        private readonly _meta: MetaService,
        private localeService: LocaleService,
        private domainSrv: DomainService
    ) {
        this.locale = this.localeService.locale;
        this.symbol = this.localeService.symbol;
        this.taxes = this.localeService.taxes;
        this.domainSrv.getCurrencyDomain$().subscribe(domain => {
            this.searchDomain = domain;
            this.getPeriod();
        });
    }

    ngOnInit() {
        // Set Meta Tags
        if (this.locale == 'es-CL') {
            this._setMetaCL();
            this.domain_table = DOMAINS_PRICE_CL;
        } else if (this.locale == 'es-PE') {
            this._setMetaPE();
            this.domain_table = DOMAINS_PRICE_PE;
        } else if (this.locale == 'es-CO') {
            this._setMetaCO();
            this.domain_table = DOMAINS_PRICE_CO;
        } else if (this.locale == 'es-MX') {
            this._setMetaME();
            this.domain_table = DOMAINS_PRICE_ME;
        } else if (this.locale == 'es-AR') {
            this._setMetaAR();
            this.domain_table = DOMAINS_PRICE_AR;
        } else {
            this._setMetaCL();
            this.domain_table = DOMAINS_PRICE_CL;
        }

        this.tableDomainesPriceTitles = this.domain_table['domaines']['table_titles'];
        this.tableDomainesInfo = this.domain_table['domaines']['table_info'];
        this.tlds = this.localeService.content.tdls;
        this.isMobile = window.innerWidth < 767 ? true : false;
    }

    getPeriod() {
        const selectedDomainZone = /(\.[\w\d]+)*$/.exec(this.searchDomain)[0].substr(1);
        this.tld = selectedDomainZone;
        const indexPeriod = this.tlds.indexOf(selectedDomainZone, true);
        this.currencyIndexPeriod = indexPeriod === -1 ? 0 : indexPeriod;
        this.selctedIndexPeriod = 0;
        this.getDomains();
    }

    private _setMetaCL() {
        this._meta.setTitle('Dominios - Hosty | Web Hosting Chile |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Con Web hosting Hosty puedes registrar tu sitio web con la extensión que desees y al mejor precio.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'dominio, registrar dominio, dominio pe, dominio y hosting, comprar dominio');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Dominios - Hosty | Web Hosting Chile |');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
        this.btnlink = 'https://panel.hosty.host/cart.php?a=add&domain=register&currency=1';
    }

    private _setMetaPE() {
        this._meta.setTitle('Dominios - Hosty | Web Hosting Perú |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Con Web hosting Hosty puedes registrar tu sitio web con la extensión que desees y al mejor precio.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'dominio, registrar dominio, dominio pe, dominio y hosting, comprar dominio');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Dominios - Hosty | Web Hosting Perú |');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
        this.btnlink = 'https://panel.hosty.host/cart.php?a=add&domain=register&currency=3';
    }

    private _setMetaCO() {
        this._meta.setTitle('Dominios - Hosty | Web Hosting Colombia |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Con Web hosting Hosty puedes registrar tu sitio web con la extensión que desees y al mejor precio.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'dominio, registrar dominio, dominio co, dominio y hosting, comprar dominio');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Dominios - Hosty | Web Hosting Colombia |');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
        this.btnlink = 'https://panel.hosty.host/cart.php?a=add&domain=register&currency=6';
    }

    private _setMetaME() {
        this._meta.setTitle('Dominios - Hosty | Web Hosting México |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Con Web hosting Hosty puedes registrar tu sitio web con la extensión que desees y al mejor precio.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'dominio, registrar dominio, dominio mx, dominio y hosting, comprar dominio');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Dominios - Hosty | Web Hosting México |');
        this._meta.setTag('og:locale', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_AR');
        this.btnlink = 'https://panel.hosty.host/cart.php?a=add&domain=register&currency=8';
    }

    private _setMetaAR() {
        this._meta.setTitle('Dominios - Hosty | Web Hosting Argentina |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Con Web hosting Hosty puedes registrar tu sitio web con la extensión que desees y al mejor precio.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'dominio, registrar dominio, dominio AR, dominio y hosting, comprar dominio');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Dominios - Hosty | Web Hosting Argentina |');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this.btnlink = 'https://panel.hosty.host/cart.php?a=add&domain=register&currency=4';
    }
    getDomains(){
        this.isLoading = true;
        this.recomendedStart();
        this.domainSrv.getDomains().subscribe( res => {
            const response = res['response'];
            this.isAvailable = response['is_available'];
            this.periods = response['prices'];
            if(this.searchDomain.split('.')[1]!= 'com'){
                this.searchDomainlink = this.btnlink + '&sld=' + this.searchDomain.split('.' , 1)
                + '&tld=.' + this.searchDomain.split('.')[1];
            }else{
                this.searchDomainlink = this.btnlink + '&sld=' + this.searchDomain.split('.' , 1)
                + '&tld=.' + this.searchDomain.split('.')[1]+'.'+this.searchDomain.split('.')[2];
            }
            this.dataRecommendZone.splice(this.dataRecommendZone.findIndex(x => x.tld == this.tld),1)
            response['related_domains'].forEach( element => {
                let vailable = false;
                let price = '';
                if( element['is_available'] ) {
                    vailable = true;
                    price = element['prices'][0]['price'];
                }
                let dom : domain_Rec = this.dataRecommendZone.find( x => '.'.concat(x.tld) == element['tld']);
                dom.price = price;
                dom.is_available = vailable;
                dom.btnLink = this.btnlink +'&tld='+element['tld']+'&sld='+this.searchDomain.split('.' , 1)
                dom.loaded = false;
                this.isLoading = false ;
            });
        });
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }
    recomendedStart(){
        this.dataRecommendZone = [];
        this.localeService.content.tdls.forEach(el => {
            this.dataRecommendZone.push({
                tld: el,
                price: '',
                btn_id:'',
                is_available: false,
                btnLink: '',
                loaded: false
            });
        });
    }
}
