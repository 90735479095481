<!-- DOMAIN PRICES  -->
<div class="domain-prices" *ngIf="searchDomain !== '' ">
  <div class="row" *ngIf="!isLoading ; else loading">
    <div class="small-12 columns">
      <p class="body2" i18n *ngIf="!isAvailable">¡Lo sentimos, <span class="text-accent font-bold">{{searchDomain}}</span> no está disponible! Considera estas opciones
        similares</p>

      <p class="body2" i18n *ngIf="isAvailable">¡Felicidades, <span class="text-accent font-bold">{{searchDomain}}</span> está disponible! Apresúrate en registrarlo antes que alguien más lo haga
        similares</p>

      <div class="selectPeriod__wrapper" *ngIf="isAvailable">
        <div class="selectPeriod__select">
          <select class="selectPeriod" [(ngModel)]="selctedIndexPeriod">
            <option *ngFor="let period of periods; let i = index" [value]="i" >
              {{period.year}} AÑO
              <span >{{symbol}}{{period.price | currencyFormat}}</span>
            </option>
          </select>
          <span>
            {{periods[selctedIndexPeriod].year}} Año {{symbol}}{{periods[selctedIndexPeriod].price | currencyFormat}}
          </span>
        </div>
        <button (click)="goToUrl(searchDomainlink)" class="btn upper" mat-button i18n>
          Comprar
        </button>
      </div>

      <div class="spacing-30"></div>
      <br>
      <br>

      <h2 i18n>DOMINIOS RECOMENDADOS</h2>
      <p class="body2" i18n>Revisa estos dominios que te sugerimos</p>

      <div class="spacing-30"></div>

      <div class="tableDomaine">
        <div class="tableDomaine__row" *ngFor="let row of dataRecommendZone">
          <div class="tableDomaine__col">{{row.tld}}</div>
          <div class="tableDomaine__col">
            <div class="inner"><span class="domain_name">{{searchDomain.split('.')[0]}}.{{row.tld}}</span></div>
            <div class="inner">
              <span class="domain_price" *ngIf="row.price !== ''">
                {{symbol}}{{row.price | currencyFormat}} <br><span i18n>Anual</span>
              </span>
              <span class="no-price" *ngIf="row.price === ''" i18n>No disponible</span>
            </div>
          </div>
          <div class="tableDomaine__col">
            <button [disabled]="!row.is_available" class="btn upper" mat-button i18n (click)="goToUrl(row.btnLink)">comprar</button>
          </div>
        </div>
      </div>

      <p class="text-center width-100 body2" i18n>Los precios indicados no incluyen IVA*</p>

    </div>
  </div>

  <div class="spacing-30"></div>
</div>
<!--  END OF PRCING BOXES COMPARISON  -->

<div class="pricingboxes-comparison v2">
  <div class="row">
    <div class="small-12 columns">

      <h2 i18n>PRECIOS DE DOMINIOS</h2>
      <p class="body2" i18n>Acá puedes elegir la extensión que le falta a tu dominio.</p>

      <!--  PRCING BOXES COMPARISON  -->
      <div class="spacing-30"></div>

      <div class="row collapse">
        <div class="small-12 large-3 columns wow zoomIn hostingfeatures" data-wow-delay="0.2s">
          <div class="title-features upper" i18n>Valores en Pesos Chilenos</div>

          <ul class="pricing-table alter features">
            <li class="bullet-item" *ngFor="let item of tableDomainesPriceTitles" i18n>{{item}}</li>
          </ul>
        </div>
        <ng-container *ngIf="!isMobile">
          <div  class="small-12 large-2 columns wow zoomIn"
                data-wow-delay="0.4s"
                *ngFor="let column of tableDomainesInfo">
            <div class="title-alt upper" i18n>{{column.title}}</div>

            <ul class="pricing-table alter">
              <li class="bullet-item" *ngFor="let price of column.list; let j = index">
                <ng-container *ngIf="price === '-'; else priceValue">
                  <span>-</span>
                </ng-container>
                <ng-template #priceValue>
                  <span i18n>{{symbol}}{{price | currencyFormat}}</span>
                </ng-template>
              </li>
            </ul>
          </div>
        </ng-container>

        <ng-container *ngIf="isMobile">
          <div  class="small-12 large-2 columns wow zoomIn"
                data-wow-delay="0.4s">
            <div class="title-alt upper rel">
              <select class="selectPeriod" [(ngModel)]="selctedIndexPeriod">
                <option *ngFor="let period of tableDomainesInfo; let i = index" [value]="i" i18n>
                  {{period.title}}
                </option>
              </select>
              <span i18n>{{tableDomainesInfo[selctedIndexPeriod].title}}</span>
            </div>

            <ul class="pricing-table alter">
              <li class="bullet-item" *ngFor="let price of tableDomainesInfo[selctedIndexPeriod].list; let j = index">
                <ng-container *ngIf="price === '-'; else priceValue">
                  <span>-</span>
                </ng-container>
                <ng-template #priceValue>
                  <span>{{symbol}}{{price | currencyFormat}}</span>
                </ng-template>
              </li>
            </ul>
          </div>
        </ng-container>

      </div>
      <p class="text-center width-100 body2" i18n>(Todos nuestros precios están en pesos chilenos (CLP), Los precios indicados no incluyen IVA* )</p>
    </div>
  </div>
</div>
<!--  END OF PRCING BOXES COMPARISON  -->
<ng-template #loading>
    <div class="small-12 columns">
        <mat-progress-bar class="domain-progress-head" mode="indeterminate" ></mat-progress-bar>

        <div class="selectPeriod__wrapper" >
          <div class="selectPeriod__select">
            <span>
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </span>
          </div>
          <button disabled="isLoading" class="btn upper" mat-button >
              Comprar
          </button>
        </div>

        <div class="spacing-30"></div>
        <br>
        <br>

        <h2 i18n>DOMINIOS RECOMENDADOS</h2>
        <p class="body2">Revisa estos dominios que te sugerimos</p>

        <div class="spacing-30"></div>

        <div class="tableDomaine">
          <div class="tableDomaine__row" *ngFor="let row of tlds">
            <div class="tableDomaine__col">{{row}}</div>
            <div class="tableDomaine__col">
                <mat-progress-bar class="domain-progress" mode="indeterminate" ></mat-progress-bar>
            </div>
            <div class="tableDomaine__col">
              <button [disabled]="isLoading" class="btn upper" mat-button >
                Comprar </button>
            </div>
          </div>
        </div>

        <p *ngIf="taxes != ''" class="text-center width-100 body2" >Los precios indicados no incluyen {{taxes}}*</p>

      </div>
</ng-template>

