import { Component, OnInit } from '@angular/core';
import { MetaService } from '@ngx-meta/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocaleService } from '../services/locale.service';
import { DATA_SITE_CL, DATA_SITE_PE, DATA_SITE_CO, DATA_SITE_ME, DATA_SITE_AR } from '../data-site';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html'
})

export class ContactComponent implements OnInit {
    locale;
    data_contacts;
    tld;
    currentPhone;
    features;
    // lazy laoding images
    showImage1: boolean = false;
    // form
    form: FormGroup;
    submitted = false;
    userName: string;
    isNameValid: boolean = false;

    constructor(
        private readonly _meta: MetaService,
        private formBuilder: FormBuilder,
        private localeService: LocaleService,
    ) {
        this.locale = this.localeService.locale;
        this.tld = this.localeService.TLD;
    }

    ngOnInit() {
        this.features = this.localeService.content.CONTACT.features;
        if (this.locale == 'es-CL') {
            this.features.filter(item => item.id === 1)[0].link = 'mailto:venta@hosty.cl';
            this.features.filter(item => item.id === 4)[0].link = 'https://docs.hosty.cl/';
            this.features.filter(item => item.id === 3)[0].link = 'tel:' + DATA_SITE_CL.tel;
            this.currentPhone = DATA_SITE_CL.phone;
        } else if (this.locale == 'es-PE') {
            this.features.filter(item => item.id === 1)[0].link = 'mailto:venta@hosty.pe';
            this.features.filter(item => item.id === 4)[0].link = 'https://docs.hosty.pe/';
            this.features.filter(item => item.id === 3)[0].link = 'tel:' + DATA_SITE_PE.tel;
            this.currentPhone = DATA_SITE_PE.phone;
        } else if (this.locale == 'es-CO') {
            this.features.filter(item => item.id === 1)[0].link = 'mailto:venta@hosty.co';
            this.features.filter(item => item.id === 4)[0].link = 'https://docs.hosty.co/';
            this.features.filter(item => item.id === 3)[0].link = 'tel:' + DATA_SITE_CO.tel;
            this.currentPhone = DATA_SITE_CO.phone;
        } else if (this.locale == 'es-MX') {
            this.features.filter(item => item.id === 1)[0].link = 'mailto:venta@hosty.mx';
            this.features.filter(item => item.id === 4)[0].link = 'https://docs.hosty.mx/';
            this.features.filter(item => item.id === 3)[0].link = 'tel:' + DATA_SITE_ME.tel;
            this.currentPhone = DATA_SITE_ME.phone;
        } else if (this.locale == 'es-AR') {
            this.features.filter(item => item.id === 1)[0].link = 'mailto:venta@hosty.com.ar';
            this.features.filter(item => item.id === 4)[0].link = 'https://docs.hosty.com.ar/';
            this.features.filter(item => item.id === 3)[0].link = 'tel:' + DATA_SITE_AR.tel;
            this.currentPhone = DATA_SITE_AR.phone;
            this.tld = 'com.ar';
        }

        this._setMeta();

        this.form = this.formBuilder.group({
            name: ['', [
                Validators.required
            ]],
            comments: ['', [
                Validators.required
            ]],
            email: ['', [
                Validators.required,
                Validators.email
            ]]
        });
    }

    onSubmit() {
        this.submitted = true;

        if (this.form.invalid) {
            return;
        }

        this.isNameValid = true;
        this.userName = this.form.get('name').value;
    }

    refresh() {
        window.location.reload();
    }

    private _setMeta() {
        this._meta.setTitle('Contacto - Hosty | Web Hosting Chile ');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Web hosting Hosty te invita a que compartas cualquier duda, consulta o comentario con nosotros. Tenemos ejecutivos disponibles para darte toda la ayuda que necesites');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', '');
    }
}
