<!--  PRCING BOXES COMPARISON  -->
<div class="pricingboxes-comparison">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>COMIENZA A CONSTRUIR TUS SUEÑOS AQUÍ.</h2>

      <p i18n>Dale rienda suelta a tus ideas con nuestros planes Web hosting Económico. Ya sea que tengas un pequeño blog o quieras dar a conocer al mundo tus ideas, estos planes están hechos para tí.</p>
    </div>
  </div>

  <div class="spacing-30"></div>

  <div class="row collapse">
    <div class="small-12 large-3 medium-3 columns wow zoomIn hostingfeatures" data-wow-delay="0.2s">
      <div class="title-features" i18n>CARACTERÍSTICAS</div>

      <ul class="pricing-table alter features">
        <li class="bullet-item" *ngFor="let item of tableCharacteristicsTitles" i18n>{{item}}</li>
      </ul>
    </div>

    <div class="small-12 large-3 medium-3 columns wow zoomIn" data-wow-delay="0.4s" *ngFor="let column of tableCharacteristicsInfo">
      <div class="title-alt" i18n>{{column.title}}</div>

      <ul class="pricing-table alter">
        <li class="bullet-item" *ngFor="let item of column.list; let j = index"><span i18n [innerHTML]="item"></span>
          <div class="show-for-small" i18n>{{tableCharacteristicsTitles[j]}}</div>
        </li>
        <li class="price" i18n>OFERTA 30% OFF<br>
          <span>{{symbol}}{{column.price | currencyFormat}} <sub>/ANUAL + IVA</sub></span></li>
        <li class="cta-button">
          <p><span><a id="{{column.btn_id}}" href="{{column.link}}" i18n>COMPRAR</a></span></p>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="pricingboxes-comparison" [preRender]="false" (deferLoad)="showImage1 = true">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>RECURSOS INCLUIDOS EN TODOS LOS PLANES</h2>

      <p i18n>Los servicios web hosting y reseller han sido creados para hospedar sitios web pequeños a pequeños y medianos</p>
      <!-- DOMAIN FEATURES   -->

      <ul class="small-block-grid-1 large-block-grid-2 medium-block-grid-2 domainfeatures">
        <li class="wow zoomIn" data-wow-delay="0.1s" *ngFor="let item of domainfeatures">
          <div class="row">
            <div class="small-12 large-3 medium-3 columns">
              <div class="circle"></div>
            </div>

            <div class="small-12 large-9 medium-9 columns">
              <h3 i18n>{{item.title}}</h3>

              <p i18n>{{item.text}}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<!--  END OF PRCING BOXES COMPARISON  -->

<!--  SHARE HOSTING FEATURES  -->
<section class="sharedfeatures-even wow fadeInUp">
  <div class="row">
    <div class="small-12 large-8 medium-8 columns">
      <h3 class="title2" i18n>SERVIDORES CON DISCOS SSD</h3>

      <p i18n>Discos SSD, mejora sustancialmente los procesos de transferencia tanto up/down, debido a que la velocidad es 300 veces mayor que un disco HDD. De entre los beneficios está la velocidad al cargar tus servicios web por ejemplo, transferir vía ftp, carga de tu sitio web, carga de cPanel. Además otras ventajas a nivel de rendimiento de los Disco SSD, mayor tiempo de duración v/s los HDD, lo que extiende la vida util y estabilidad al servidor.</p>
    </div>

    <div class="small-12 large-4 medium-4 columns">
      <div class="circle"></div>
    </div>
  </div>
</section>

<section class="sharedfeatures-odd wow fadeInUp">
  <div class="row">
    <div class="small-12 large-4 medium-4 columns">
      <div class="circle"></div>
    </div>

    <div class="small-12 large-8 medium-8 columns">
      <h3 class="title2" i18n>TUS ARCHIVOS RESPALDADOS</h3>

      <p i18n>
        Pensando en tu seguridad y tranquilidad, en HOSTY realizamos respaldos semanales y mensuales. Lo que buscamos es salvaguardar los datos de nuestros servidores para recuperarlos en caso de cualquier incidente. Para mayor detalle revisa:
        <a href="https://www.haulmer.com/legal/web-hosting/" target="_blank"> https://www.haulmer.com/legal/web-hosting/ </a>
      </p>
    </div>
  </div>
</section>

<section class="sharedfeatures-even wow fadeInUp">
  <div class="row">
    <div class="small-12 large-8 medium-8 columns">
      <h3 class="title2" i18n>SEGURIDAD PRIMERO</h3>

      <p i18n>Enfocados en darte un servicio de calidad, utilizamos las mejores herramientas para protegerte de spam y posibles amenazas a tus datos. Con Anti-Spam ASSP, podrás bloquear mas del 80% de spam de forma rápida y sencilla, sin un mayor uso de recursos y altamente automatizable. Junto a eso, generamos revisiones semanales de seguridad actualizadas con AtomiCorp GotRoot ModSecurity Rules, uno de los más antiguos, más confiables y utilizados Firewalls enfocados en aplicaciones web del mercado.</p>
    </div>

    <div class="small-12 large-4 medium-4 columns">
      <div class="circle"></div>
    </div>
  </div>
</section>

<section class="sharedfeatures-odd wow fadeInUp">
  <div class="row">
    <div class="small-12 large-4 medium-4 columns">
      <div class="circle"></div>
    </div>

    <div class="small-12 large-8 medium-8 columns">
      <h3 class="title2" i18n>SOPORTE ESPECIALIZADO</h3>

      <p i18n>Ya no es necesario preocuparse si posees alguna duda o consulta. Nuestro servicio de Hosting ofrece soporte especializado de Lunes a Viernes, tanto por vía ticket, chat y teléfono. Contamos con ingenieros especialistas en el tema, los cuales se encuentran disponibles y a la espera de resolver de la mejor forma sus problemas.</p>
    </div>
  </div>
</section>

<section class="sharedfeatures-even wow fadeInUp">
  <div class="row">
    <div class="small-12 large-8 medium-8 columns">
      <h3 class="title2" i18n>DATACENTER PROPIO</h3>

      <p i18n>Buscando darte un servicio rápido y estable, contamos con nuestro propio datacenter. Su estructura está enfocada en darte respuestas oportunas utilizando enlaces redundantes y balanceados, junto a un sistema de autonomía energética y monitoreo constante. Poseemos servidores RAID 10, cuya ventaja es disponer de redundancia y alto nivel de rendimiento.</p>
    </div>

    <div class="small-12 large-4 medium-4 columns">
      <div class="circle"></div>
    </div>
  </div>
</section>
<!--  SHARE HOSTING FEATURES  -->

<!-- PRODUCTOS ADICIONALES   -->
<!--
<div class="dedicated-servers-features floralwhite">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>PRODUCTOS ADICIONALES</h2>

      <p i18n>Acá podrás ver todos los productos adicionales que te ofrecen los planes Webhosting.</p>

      <ul class="small-block-grid-1 large-block-grid-2 medium-block-grid-2">
        <li class="wow fadeInLeft" [ngClass]="item.class" data-wow-delay="0.3s" *ngFor="let item of additionalProducts">
          <div class="row">
            <div class="small-12 large-3 medium-3 columns">
              <ng-container *ngIf="showImage1">--> <!-- showImage1 because we need render before. -->
                <!--
                <img alt="" src="/assets/images/web-hosting/{{item.img}}.png">
              </ng-container>

            </div>

            <div class="small-12 large-9 medium-9 columns">
              <h3 i18n>{{item.title}}</h3>

              <h5>{{symbol}}{{item.price | currencyFormat}} {{taxez}} </h5>

              <p i18n>{{item.text}}</p>

              <p i18n>{{item.sub_text}}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
-->
<!-- PRODUCTOS ADICIONALES END   -->
