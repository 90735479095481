import { Component, OnInit } from '@angular/core';
import { MetaService } from '@ngx-meta/core';
import { LocaleService } from '../services/locale.service';
import { URL } from '../../environments/environment';
import { DATA_SITE_CL, DATA_SITE_PE, DATA_SITE_CO, DATA_SITE_ME, DATA_SITE_AR } from '../data-site';

@Component({
    selector: 'app-promo',
    templateUrl: './promo.component.html'
})

export class PromoComponent implements OnInit {
    URL = URL;
    locale;

    link_option_1;
    link_option_2;

    // lazy laoding images
    showImage1: boolean = false;

    constructor(
        private readonly _meta: MetaService,
        private localeService: LocaleService,
    ) {
        this.locale = this.localeService.locale;
    }

    ngOnInit() {
        // Set Meta Tags
        if (this.locale == 'es-CL') {
            this._setMetaCL();
            this.link_option_1 = DATA_SITE_CL.apply_now_link;
            this.link_option_2 = DATA_SITE_CL.buy_now_link;
        } else if (this.locale == 'es-PE') {
            this._setMetaPE();
            this.link_option_1 = DATA_SITE_PE.apply_now_link;
            this.link_option_2 = DATA_SITE_PE.buy_now_link;
        } else if (this.locale == 'es-CO') {
            this._setMetaCO();
            this.link_option_1 = DATA_SITE_CO.apply_now_link;
            this.link_option_2 = DATA_SITE_CO.buy_now_link;
        } else if (this.locale == 'es-MX') {
            this._setMetaME();
            this.link_option_1 = DATA_SITE_ME.apply_now_link;
            this.link_option_2 = DATA_SITE_ME.buy_now_link;
        } else if (this.locale == 'es-AR') {
            this._setMetaAR();
            this.link_option_1 = DATA_SITE_AR.apply_now_link;
            this.link_option_2 = DATA_SITE_AR.buy_now_link;
        } else {
            this._setMetaCL();
            this.link_option_1 = DATA_SITE_CL.apply_now_link;
            this.link_option_2 = DATA_SITE_CL.buy_now_link;
        }
    }

    private _setMetaCL() {
        this._meta.setTitle('Promo 10 Días Gratis - Hosty | Web Hosting Chile |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Web hosting Hosty te invita a que compartas cualquier duda, consulta o comentario con nosotros. Tenemos ejecutivos disponibles para darte toda la ayuda que necesites..');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Promo 10 Días Gratis - Hosty | Web Hosting Chile |');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaPE() {
        this._meta.setTitle('Promo 10 Días Gratis - Hosty | Web Hosting Perú |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Web hosting Hosty te invita a que compartas cualquier duda, consulta o comentario con nosotros. Tenemos ejecutivos disponibles para darte toda la ayuda que necesites');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Promo 10 Días Gratis - Hosty | Web Hosting Perú |');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaCO() {
        this._meta.setTitle('Promo 10 Días Gratis - Hosty | Web Hosting Colombia |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Web hosting Hosty te invita a que compartas cualquier duda, consulta o comentario con nosotros. Tenemos ejecutivos disponibles para darte toda la ayuda que necesites');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Promo 10 Días Gratis - Hosty | Web Hosting Colombia |');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaME() {
        this._meta.setTitle('Promo 10 Días Gratis - Hosty | Web Hosting México |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Web hosting Hosty te invita a que compartas cualquier duda, consulta o comentario con nosotros. Tenemos ejecutivos disponibles para darte toda la ayuda que necesites');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Promo 10 Días Gratis - Hosty | Web Hosting México |');
        this._meta.setTag('og:locale', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }
    private _setMetaAR() {
        this._meta.setTitle('Promo 10 Días Gratis - Hosty | Web Hosting Argentina |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Web hosting Hosty te invita a que compartas cualquier duda, consulta o comentario con nosotros. Tenemos ejecutivos disponibles para darte toda la ayuda que necesites');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Promo 10 Días Gratis - Hosty | Web Hosting Argentina |');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
}
