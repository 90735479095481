import { Component, Input } from '@angular/core';

export interface INoResult {
    icon: string;
    title: string;
    text: string;
    state?: string;
    type?: string;
}

@Component({
    selector: 'app-no-search-result',
    templateUrl: './no-search-result.component.html',
    styleUrls: ['./no-search-result.component.scss']
})
export class NoSearchResultComponent {
    @Input() public data: INoResult;

}
