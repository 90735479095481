import { Component, OnInit, Input } from '@angular/core';
import { generateColumns } from '../../../../../common/functions/column-functions';
interface IColumnProduct {
    text?: Array<string>;
    code?: number;
    region?: string;
    tax?: string;
    prefixText?: string;
    value?: string;
    button?: Object;
}

interface IResultTableColumns {
    columns: Array<IColumnProduct>;
}

export interface IResultTable {
    header: Array<string>;
    body: Array<IResultTableColumns>;
}

@Component({
    selector: 'app-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {
    @Input() data;
    public showAlertMessage: boolean = false;
    public linkType: string;
    public dataResult: IResultTable = {
        header: [
            'Descripción del producto',
            'N° Orden',
            'Total a Pagar',
            'Método de pago'
        ],
        body: []
    }

    constructor(
    ) { }

    ngOnInit(): void {
        if (this.data.show) {
            if (this.data.status === 'found') {
                [this.data, this.linkType, this.dataResult] = generateColumns(this.data, this.linkType, this.dataResult);
            }
            if (this.data.status === 'Found finished') {
                this.showAlertMessage = true;
            }
        }
    }

}
