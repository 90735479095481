<header [ngClass]="[getDataHeader().class, (!this.router.url.includes('/success') ? 'custom_header':'')]">
  <div class="top sticky">
    <div class="row">
      <div class="menucliente">
        <nav class="subcliente">
          <a href="tel:{{data_header.tel}}">{{data_header.phone}}</a>
          <a id="h-ayuda" href="https://docs.hosty.{{tld}}/" i18n>Ayuda</a>
          <a href="https://survey.typeform.com/to/zQ7I32J9#empresa=Hosty&issuetype=11208">Sugerencias o reclamos</a>
          <a id="h-chat" class="link_chat slaask-open-widget" i18n>Live Chat</a>
          <a id="h-cliente" href="https://panel.hosty.host" i18n>Área Cliente</a>
          <a id="h-pago" [routerLink]="['/pago-directo']" i18n>Pago de Cuentas</a>
        </nav>
      </div>

      <div class="small-12 large-3 medium-3 columns">
        <div class="logo">
          <a [routerLink]="['/']">
              <img alt="logo" src="/assets/images/logo.png">
          </a>
        </div>
      </div>

      <div class="small-12 large-9 medium-9 columns navMobile__menu">
        <a class="navMobile__btn" [class.open-menu]="isOpenMobileMenu" (click)="toggleMobileMenu()">
          <span class="navMobile__btn-txt">MENU</span>
          <span class="navMobile__btn-icon">
            <span class="navMobile__btn-iconbar"></span>
            <span class="navMobile__btn-iconbar"></span>
            <span class="navMobile__btn-iconbar"></span>
          </span>
        </a>
        <!--  NAVIGATION MENU AREA -->
        <nav class="desktop-menu">
          <ul class="sf-menu">
            <li><a [routerLink]="['/']" [class.active]="getDataHeader().class === 'home'" (click)="closeMenu()">HOME</a></li>
            <li class="dropdown" (mouseover)="addClassHover($event)" (mouseout)="removeClassHover($event)">
              <a (click)="toggleDropdownOnMobile($event)">HOSTING <mat-icon>trending_flat</mat-icon></a>
              <ul>
                <li>
                  <a id="h-hosting" [routerLink]="['/web-hosting']" [routerLinkActive]="['active']" (click)="closeMenu()">HOSTING</a>
                </li>
                <li><a id="h-reseller" [routerLink]="['/web-hosting-reseller']" [routerLinkActive]="['active']" (click)="closeMenu()">RESELLER</a>
                </li>
                <li>
                  <a id="h-dominio" [routerLink]="['/dominios']" [routerLinkActive]="['active']" (click)="closeMenu()">Dominios</a>
                </li>
              </ul>
            </li>
            <li>
              <a id="h-pyme" [routerLink]="['/web-hosting-pyme']" [routerLinkActive]="['active']" (click)="closeMenu()">HOSTING PYME</a>
            </li>

            <li class="dropdown" (mouseover)="addClassHover($event)" (mouseout)="removeClassHover($event)">
              <a (click)="toggleDropdownOnMobile($event)">VPS <mat-icon>trending_flat</mat-icon></a>
              <ul>
                <li>
                  <a id="h-vps" [routerLink]="['/cloud-vps']" [routerLinkActive]="['active']" (click)="closeMenu()">VPS</a>
                </li>
                <li>
                  <a id="h-vps" [routerLink]="['/cloud-vps-backup']" [routerLinkActive]="['active']" (click)="closeMenu()">VPS + BACKUP</a>
                </li>
              </ul>
            </li>
            <li>

            <li class="dropdown" (mouseover)="addClassHover($event)" (mouseout)="removeClassHover($event)">
              <a (click)="toggleDropdownOnMobile($event)">NOSOTROS <mat-icon>trending_flat</mat-icon></a>
              <ul>
                <li>
                  <a id="h-youtube" href="https://www.youtube.com/channel/UC9yYDe-kzsfAqrIhLr0kgoA" (click)="closeMenu()">CANAL DE YOUTUBE</a>
                </li>
                <li>
                  <a id="h-tutoriales" href="https://docs.hosty.{{tld}}/" (click)="closeMenu()">AYUDA Y TUTORIALES</a>
                </li>
                <li>
                  <a id="h-auspicio" [routerLink]="['/programa-auspicio']" [routerLinkActive]="['active']" (click)="closeMenu()">PROGRAMA DE AUSPICIO</a>
                </li>
                <li>
                  <a id="h-afiliados" [routerLink]="['/programa-afiliados']" [routerLinkActive]="['active']" (click)="closeMenu()">PROGRAMA DE AFILIADOS</a>
                </li>
                <li>
                  <a id="h-forpagos" [routerLink]="['/formas-de-pago']" [routerLinkActive]="['active']" (click)="closeMenu()">FORMAS DE PAGO</a>
                </li>
                <li>
                  <a id="h-logotipos" [routerLink]="['/centro-de-logotipos']" [routerLinkActive]="['active']" (click)="closeMenu()">CENTRO DE LOGOTIPOS</a>
                </li>
                <li>
                  <a id="h-términos" [href]="URL.HOSTY_TERMS_CONDITIONS" (click)="closeMenu()">TÉRMINOS Y CONDICIONES</a>
                </li>
                <li><a id="h-blog" href="https://www.hosty.{{tld}}/blog" (click)="closeMenu()">BLOG</a></li>
              </ul>
            </li>
            <li><a [routerLink]="['/contacto']" [routerLinkActive]="['active']" (click)="closeMenu()">CONTACTO &amp; SOPORTE</a></li>
            <div class="submenu">
              <li><a href="tel:{{data_header.tel}}">{{data_header.phone}}</a></li>
              <li><a id="h-ayuda" href="https://docs.hosty.{{tld}}/" i18n>Ayuda</a></li>
              <li><a href="https://survey.typeform.com/to/zQ7I32J9#empresa=Hosty&issuetype=11208">Sugerencias o reclamos</a></li>
              <li><a id="h-chat" class="link_chat slaask-open-widget" i18n>Live Chat</a></li>
              <li><a id="h-cliente" href="https://panel.hosty.host" i18n>Área Cliente</a></li>
              <li><a id="h-pago" [routerLink]="['/pago-directo']" (click)="closeMenu()" i18n>Pago de Cuentas</a></li>
            </div>
          </ul>
        </nav>
        <!--  END OF NAVIGATION MENU AREA -->
      </div>
    </div>
  </div>

  <!--  MESSAGES ABOVE HEADER IMAGE -->
  <div class="message">
    <div class="row">
      <div class="small-12 columns">
        <div class="message-intro" *ngIf="getDataHeader().class !== 'direct-payment'">
          <span class="message-line" *ngIf="getDataHeader().has_line"></span>
          <ng-container *ngIf="getDataHeader().class === 'home'; else textInnerPage">
            <h1 class="title1" i18n>{{getDataHeader().title1}}</h1>
          </ng-container>
          <ng-template #textInnerPage>
            <div class="title" i18n>{{getDataHeader().title1}}</div>
          </ng-template>
          <span class="message-line" *ngIf="getDataHeader().has_line"></span>
        </div>
        <ng-container *ngIf="getDataHeader().class !== 'home'; else textHome">
          <h1 class="title1" i18n>{{getDataHeader().message_text}}</h1>
        </ng-container>
        <ng-template #textHome>
          <div class="title1">
            <span class="morphext" *ngIf="getDataHeader().class === 'home'">
              <span class="hidden" i18n>UPTIME 99%</span>
              <span class="wow bounceInLeft" data-wow-delay=".3s" *ngIf="isShowText" i18n>{{titleInfo}}</span>
            </span>
          </div>
        </ng-template>

        <a class="small radius button"
          (click)="navigateToSection('pricingboxes')"
          *ngIf="getDataHeader().class === 'home'" i18n>VER PLANES</a>

        <ng-container *ngIf="getDataHeader().class === 'alt-4'">
          <!-- DOMAIN NAME SEARCH BOX -->
          <div class="domainsearch">
            <div class="row collapse">
              <div class="small-12 large-10 medium-10 large-centered medium-centered columns">
                <form method="post" [formGroup]="form" (ngSubmit)="onSubmit()">
                  <div class="row collapse">
                    <div class="small-12 large-8 medium-8 columns">
                      <input type="text"
                      #inputSearch
                      formControlName="text" name="sld" placeholder="Ingrese su sitio">
                    </div>
                    <div class="small-12 large-2 medium-2 columns">
                      <select id="tld" name="tld" >
                        <option *ngFor="let tld of tlds">.{{tld}}</option>
                      </select>
                    </div>
                    <div class="small-12 large-2 medium-2 columns">
                      <input [disabled]="!form.valid" class="btn-wide" type="submit" value="Buscar" name="submit">
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
          <!-- END OF DOMAIN NAME SEARCH BOX -->
        </ng-container>

      </div>
    </div>
  </div>
  <!--  END OF MESSAGES ABOVE HEADER IMAGE -->

  <app-search-form *ngIf="getDataHeader().class === 'direct-payment'"></app-search-form>

</header>
