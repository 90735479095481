import { Component, OnInit, Inject, PLATFORM_ID, HostListener, ViewChild, ElementRef } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { MetaService } from '@ngx-meta/core';
import { URL } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';
import { DATA_PLANS_CL } from '../content/content-cl';
import { DATA_PLANS_PE } from '../content/content-pe';
import { DATA_PLANS_CO } from '../content/content-co';
import { DATA_PLANS_ME } from '../content/content-me';
import { DATA_PLANS_AR } from '../content/content-ar';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit {
    @ViewChild('percentAnimateTrigger1', { static: true }) percentAnimateTrigger1: ElementRef;
    @ViewChild('lovedbyAnimationTrigger', { static: true }) lovedbyAnimationTrigger: ElementRef;

    URL = URL;
    locale;
    symbol = '';
    taxez: string;
    // lazy laoding images
    showImage1: boolean = false;

    // data plans
    DATA_PLANS;
    dataPlans;

    // number animation
    numberIsAnimated: boolean = false;
    curPerc = 0;
    maxPerc = 30;

    numberIsAnimated2: boolean = false;
    curLovedby = 0;
    maxLovedby: number = 41169;
    // end number animation

    // slider
    customOptions: any = {
        animateIn: 'zoomInRight',
        animateOut: 'zoomOutLeft',
        items: 1,
        autoplay: true,
        loop: true
    };
    // slider data
    slidesStore;

    constructor(
        private router: Router,
        private wowService: NgwWowService,
        @Inject(PLATFORM_ID) private platformId: any,
        private readonly _meta: MetaService,
        private localeService: LocaleService,
    ) {
        this.locale = this.localeService.locale;
        this.symbol = this.localeService.symbol;
        this.taxez = this.localeService.taxes;
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(event => {
            // Reload WoW animations when done navigating to page,
            if (isPlatformBrowser(this.platformId)) {
                this.wowService.init();
            }
        });
    }

    @HostListener('window: scroll')
    onScroll() {
        if (isPlatformBrowser(this.platformId)) {
            this.animateNumbers();
        }
    }

    ngOnInit() {
        this.slidesStore = this.localeService.content.HOME.slidesStore;
        // Set Meta Tags
        this.slidesStore = this.localeService.content.HOME.slidesStore;
        if (this.locale == 'es-CL') {
            this._setMetaCL();
            this.DATA_PLANS = DATA_PLANS_CL;
        } else if (this.locale == 'es-PE') {
            this._setMetaPE();
            this.DATA_PLANS = DATA_PLANS_PE;
        } else if (this.locale == 'es-CO') {
            this._setMetaCO();
            this.DATA_PLANS = DATA_PLANS_CO;
        } else if (this.locale == 'es-MX') {
            this._setMetaME();
            this.DATA_PLANS = DATA_PLANS_ME;
        } else if (this.locale == 'es-AR') {
            this._setMetaAR();
            this.DATA_PLANS = DATA_PLANS_AR;
        } else {
            this._setMetaCL();
            this.DATA_PLANS = DATA_PLANS_CL;
        }

        this.dataPlans = this.DATA_PLANS['home'];
    }

    animateNumbers() {
        const percentAnimateTrigger1 = this.percentAnimateTrigger1.nativeElement;
        const lovedbyAnimationTrigger = this.lovedbyAnimationTrigger.nativeElement;

        if (this.isInViewport(percentAnimateTrigger1) && !this.numberIsAnimated) {
            setTimeout(() => {
                this.animatePercent();
            }, 1000);
            this.numberIsAnimated = true;
        }

        if (this.isInViewport(lovedbyAnimationTrigger) && !this.numberIsAnimated2) {
            setTimeout(() => {
                this.animateLovedby();
            }, 100);
            this.numberIsAnimated2 = true;
        }
    }

    animatePercent() {
        if (isPlatformBrowser(this.platformId)) {
            if (this.curPerc < this.maxPerc) {
                this.curPerc++;
                requestAnimationFrame(() => {
                    this.animatePercent();
                });
            }
        }
    }

    animateLovedby() {
        if (isPlatformBrowser(this.platformId)) {
            const ratio = this.maxLovedby / 60 / 4;
            const fps = parseInt(ratio.toString(), 10);

            if (this.curLovedby < this.maxLovedby - fps) {
                this.curLovedby += fps;
            } else if (this.curLovedby < this.maxLovedby) {
                this.curLovedby += this.maxLovedby - this.curLovedby;
            }

            requestAnimationFrame(() => {
                this.animateLovedby();
            });
        }
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    isInViewport(elem) {
        if (!elem) { return false; }
        const elemRect = elem.getBoundingClientRect();
        return (elemRect.top <= (window.innerHeight - window.innerHeight * 0.2));
    }

    private _setMetaCL() {
        this._meta.setTitle('HOSTING ECONÓMICO | Web Hosting Más Económico de Chile $14.900 Anual');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'HOSTING Más Económico de Todo Chile con Nivel de Calidad Incomparables en Uptime, Seguridad y Estabilidad nuestra GAMA de Servicios Web Hosting.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting,web hosting, hosting chile,alojamiento web, hospedaje web, hosting joomla, hosting wordpress, web hosting phpbb');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'HOSTING ECONÓMICO | Web Hosting Más Económico de Chile $14.900 Anual');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaPE() {
        this._meta.setTitle('HOSTING ECONÓMICO | Web Hosting Más Económico de Perú S. 75 Anual');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'HOSTING Más Económico de Todo Perú con Nivel de Calidad Incomparables en Uptime, Seguridad y Estabilidad nuestra GAMA de Servicios Web Hosting.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, web hosting, hosting perú, alojamiento web, hospedaje web, hosting joomla, hosting wordpress, web hosting phpbb');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'HOSTING ECONÓMICO | Web Hosting Más Económico de Perú S. 75 Anual');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaCO() {
        this._meta.setTitle('HOSTING ECONÓMICO | Web Hosting Más Económico de Colombia $70.000 Anual');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'HOSTING Más Económico de toda Colombia con Nivel de Calidad Incomparables en Uptime, Seguridad y Estabilidad nuestra GAMA de Servicios Web Hosting.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, web hosting, hosting colombia, alojamiento web, hospedaje web, hosting joomla, hosting wordpress, web hosting phpbb');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'HOSTING ECONÓMICO | Web Hosting Más Económico de Colombia $70.000 Anual');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaME() {
        this._meta.setTitle('HOSTING ECONÓMICO | Web Hosting Más Económico de México $430 Anual');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'HOSTING Más Económico de Todo México con Nivel de Calidad Incomparables en Uptime, Seguridad, y Estabilidad nuestra GAMA de Servicios Web Hosting.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, web hosting, hosting México, alojamiento web, hospedaje web, hosting joomla, hosting wordpress, web hosting phpbb');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'HOSTING ECONÓMICO | Web Hosting Más Económico de México $430 Anual');
        this._meta.setTag('og:locale', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaAR() {
        this._meta.setTitle('HOSTING ECONÓMICO | Web Hosting Más Económico de Argentina $910 Anual');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'HOSTING Más Económico de toda Argentina con Nivel de Calidad Incomparables en Uptime, Seguridad y Estabilidad nuestra GAMA de Servicios Web Hosting.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, web hosting, hosting Argentina, alojamiento web, hospedaje web, hosting joomla, hosting wordpress, web hosting phpbb');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'HOSTING ECONÓMICO | Web Hosting Más Económico de Argentina $910 Anual');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
    }
}
