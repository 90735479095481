<!--  PRCING BOXES COMPARISON  -->
<div class="pricingboxes-comparison">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>ESTABILIDAD Y RENDIMIENTO.</h2>

      <p i18n>¿Tu proyecto ha crecido y necesitas más capacidad? Acá encontrarás más solidez y mejores recursos enfocados en potenciar tus sitios y proyectos.</p>
    </div>
  </div>

  <div class="spacing-30"></div>

  <div class="row collapse">
    <div class="small-12 large-3 medium-3 columns wow zoomIn hostingfeatures" data-wow-delay="0.2s">
      <div class="title-features" i18n>CARACTERÍSTICAS</div>

      <ul class="pricing-table alter features">
        <li class="bullet-item" *ngFor="let item of tableCharacteristicsTitles" i18n>{{item}}</li>
      </ul>
    </div>


    <div class="small-12 large-3 medium-3 columns wow zoomIn" data-wow-delay="0.4s" *ngFor="let column of tableCharacteristicsInfo">
      <div class="title-alt" i18n>{{column.title}}</div>

      <ul class="pricing-table alter">
        <li class="bullet-item" *ngFor="let item of column.list; let j = index"><span i18n [innerHTML]="item"></span>
          <div class="show-for-small" i18n>{{tableCharacteristicsTitles[j]}}</div>
        </li>
        <li class="price" i18n>OFERTA 30% OFF<br>
          <span>{{symbol}}{{column.price | currencyFormat}}<sub>/ANUAL + IVA</sub></span></li>
        <li class="cta-button">
          <p><span><a id="{{column.btn_id}}" href="{{column.link}}" i18n>COMPRAR</a></span></p>
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- START SHARE HOSTING FEATURES  -->
<section class="sharedfeatures-even wow fadeInUp" [preRender]="false" (deferLoad)="showImage1 = true">
  <div class="row">
    <div class="small-12 large-8 medium-8 columns">
      <h3 class="title2" i18n>SERVIDORES CON DISCOS SSD</h3>

      <p i18n>Discos SSD, mejora sustancialmente los procesos de transferencia tanto up/down, debido a que la velocidad es 300 veces mayor que un disco HDD. De entre los beneficios está la velocidad al cargar tus servicios web por ejemplo, transferir vía ftp, carga de tu sitio web, carga de cPanel. Además otras ventajas a nivel de rendimiento de los Disco SSD, mayor tiempo de duración v/s los HDD, lo que extiende la vida util y estabilidad al servidor.</p>
    </div>

    <div class="small-12 large-4 medium-4 columns">
      <div class="circle"></div>
    </div>
  </div>
</section>
<!-- END SHARE HOSTING FEATURES  -->

<!-- START DOMAIN FEATURES  -->
<div class="domainfeatures-list">
  <div class="row">
    <div class="small-12 columns">
      <ul class="small-block-grid-1 large-block-grid-2 medium-block-grid-2 domainfeatures">
        <li class="wow zoomIn" data-wow-delay="0.1s" *ngFor="let item of domainfeatures">
          <div class="row">
            <div class="small-12 large-3 medium-3 columns">
              <div class="circle"></div>
            </div>

            <div class="small-12 large-9 medium-9 columns">
              <h3 i18n>{{item.title}}</h3>

              <p i18n>{{item.text}}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- END DOMAIN FEATURES  -->
<!--  END OF PRCING BOXES COMPARISON  -->

<!-- PRODUCTOS ADICIONALES   -->
<!--
<div class="dedicated-servers-features floralwhite">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>PRODUCTOS ADICIONALES</h2>

      <p i18n>Acá podrás ver todos los productos adicionales que te ofrecen los planes Webhosting PYME.</p>

      <ul class="small-block-grid-1 large-block-grid-2 medium-block-grid-2">
        <li class="wow fadeInLeft" [ngClass]="item.class" data-wow-delay="0.3s" *ngFor="let item of additionalProducts">
          <div class="row">
            <div class="small-12 large-3 medium-3 columns">
              <ng-container *ngIf="showImage1">--><!-- showImage1 because we need render before. -->
                <!--
                <img alt="" src="/assets/images/web-hosting/{{item.img}}.png">
              </ng-container>
            </div>

            <div class="small-12 large-9 medium-9 columns">
              <h3 i18n>{{item.title}}</h3>

              <h5>{{symbol}}{{item.price | currencyFormat}} {{taxez}} </h5>

              <p i18n>{{item.text}}</p>

              <p i18n>{{item.sub_text}}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
-->
<!-- PRODUCTOS ADICIONALES END   -->
