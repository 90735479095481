<form class="searchForm" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="searchForm__field">
    <input
      #searchInput
      [class.filled]="form.dirty"
      type="search"
      formControlName="search"
      maxlength="63"
      minlength="5">
    <span class="custom-placeholder" i18n>Ingresa tu dominio, N° de Orden o correo electrónico</span>
  </div>
  <button mat-button type="submit"  [disabled]="(form.invalid || isLoading)" i18n>Consultar deuda</button>
</form>