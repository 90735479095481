import { Injectable } from "@angular/core";
import { IInvoiceResponse } from "../interfaces/invoice.interface";
import { Observable, Subject } from "rxjs";

@Injectable()
export class SearchService {
    public showNoResult$ = new Subject<IInvoiceResponse>();
    public isLoading$ = new Subject<boolean>();
    public complete$ = new Subject<IInvoiceResponse>();

    public getShowNoResult$(): Observable<IInvoiceResponse> {
        return this.showNoResult$.asObservable();
    }

    public setShowNoResult(value: IInvoiceResponse) {
        this.showNoResult$.next(value);
    }

    public getIsLoading$(): Observable<boolean> {
        return this.isLoading$.asObservable();
    }

    public setIsLoading(value: boolean): void {
        this.isLoading$.next(value);
    }

    public getComplete$(): Observable<IInvoiceResponse> {
        return this.complete$.asObservable();
    }

    public setComplete(value: IInvoiceResponse) {
        this.complete$.next(value);
    }

}
