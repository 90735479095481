// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export class URL {
  // DEVELOP.
  public static readonly HOSTY_FACEBOOK = 'https://www.facebook.com/HostyLAT/';
  public static readonly HOSTY_TWITTER = 'https://twitter.com/HostyLAT';
  public static readonly HOSTY_YOUTUBE = 'https://www.youtube.com/channel/UC9yYDe-kzsfAqrIhLr0kgoA';
  public static readonly HOSTY_API_FRONTEND = 'https://api.haulmer.dev/v1/hosty/';
  public static readonly HOSTY_BLOG = 'https://hosty-blog.haulmer.com/blog/';
  public static readonly HOSTY_WEBSITE = 'https://website-hosty-cl.haulmer.dev/';
  public static readonly FIND_INVOICE = "https://whmcs-base.haulmer.dev/includes/api/findInvoices.php";
  public static readonly HOSTY_TERMS_CONDITIONS = "https://www.haulmer.com/legal/tag/web-hosting/";
}
