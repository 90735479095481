import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MetaService } from '@ngx-meta/core';
import { URL } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';
import { CHARACTERISTICS_TABLE_CL } from '../content/content-cl';
import { CHARACTERISTICS_TABLE_PE } from '../content/content-pe';
import { CHARACTERISTICS_TABLE_CO } from '../content/content-co';
import { CHARACTERISTICS_TABLE_ME } from '../content/content-me';
import { CHARACTERISTICS_TABLE_AR } from '../content/content-ar';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-web-hosting',
    templateUrl: './web-hosting.component.html'
})

export class WebHostingComponent implements OnInit {
    URL = URL;
    locale;
    taxez: string;
    symbol = '';
    characteristics_table;
    tableCharacteristicsTitles;
    tableCharacteristicsInfo;
    domainfeatures;
    additionalProducts;

    // lazy laoding images
    showImage1: boolean = false;

    constructor(
        private router: Router,
        private wowService: NgwWowService,
        private readonly _meta: MetaService,
        private localeService: LocaleService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        this.locale = this.localeService.locale;
        this.symbol = this.localeService.symbol;
        this.taxez = this.localeService.taxes;
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(event => {
            // Reload WoW animations when done navigating to page,
            if (isPlatformBrowser(this.platformId)) {
                this.wowService.init();
            }
        });
    }

    ngOnInit() {
        this.domainfeatures = this.localeService.content.WEB_HOSTING.domainfeatures;
        this.additionalProducts = this.localeService.content.WEB_HOSTING.additionalProducts;
        // Set Meta Tags
        if (this.locale == 'es-CL') {
            this._setMetaCL();
            this.characteristics_table = CHARACTERISTICS_TABLE_CL;
        } else if (this.locale == 'es-PE') {
            this._setMetaPE();
            this.characteristics_table = CHARACTERISTICS_TABLE_PE;
        } else if (this.locale == 'es-CO') {
            this._setMetaCO();
            this.characteristics_table = CHARACTERISTICS_TABLE_CO;
        } else if (this.locale == 'es-MX') {
            this._setMetaME();
            this.characteristics_table = CHARACTERISTICS_TABLE_ME;
        } else if (this.locale == 'es-AR') {
            this._setMetaAR();
            this.characteristics_table = CHARACTERISTICS_TABLE_AR;
        } else {
            this._setMetaCL();
            this.characteristics_table = CHARACTERISTICS_TABLE_CL;
        }

        this.tableCharacteristicsTitles = this.characteristics_table['web_hosting']['table_titles'];
        this.tableCharacteristicsInfo = this.characteristics_table['web_hosting']['table_info'];
    }

    private _setMetaCL() {
        this._meta.setTitle('Planes Económico - Hosty | Web Hosting Chile |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Los planes web hosting persona de Hosty, están creados para que comiences a crear tus proyectos online con el mejor servicio y al mejor precio.');
        this._meta.setTag('keywords', 'web hosting, hosting, hosting chile, planes hosting');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Planes Económico - Hosty | Web Hosting Chile |');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaPE() {
        this._meta.setTitle('Planes Económico - Hosty | Web Hosting Perú |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Los planes web hosting persona de Hosty, están creados para que comiences a crear tus proyectos online con el mejor servicio y al mejor precio');
        this._meta.setTag('keywords', 'web hosting, hosting, hosting perú, planes hosting');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Planes Económico - Hosty | Web Hosting Perú |');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaCO() {
        this._meta.setTitle('Planes Económicos - Hosty | Web Hosting Colombia |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Los planes web hosting persona de Hosty, están creados para que comience a crear sus proyectos online con el mejor servicio y al mejor precio.');
        this._meta.setTag('keywords', 'web hosting, hosting, hosting colombia, planes hosting');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Planes Económico - Hosty | Web Hosting Colombia |');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaME() {
        this._meta.setTitle('Planes Económico - Hosty | Web Hosting México |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Los planes web hosting persona de Hosty, están creados para que comiences a crear tus proyectos online con el mejor servicio y al mejor precio.');
        this._meta.setTag('keywords', 'web hosting, hosting, hosting México, planes hosting');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Planes Económico - Hosty | Web Hosting México |');
        this._meta.setTag('og:locale', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }

    private _setMetaAR() {
        this._meta.setTitle('Planes Económico - Hosty | Web Hosting Argentina |');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Los planes web hosting persona de Hosty, están creados para que comencés a crear tus proyectos online con el mejor servicio y al mejor precio.');
        this._meta.setTag('keywords', 'web hosting, hosting, hosting Argentina, planes hosting');
        this._meta.setTag('author', 'Hosty');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.HOSTY_WEBSITE + '/assets/images/og/og-home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Planes Económico - Hosty | Web Hosting Argentina |');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }

}
