export function generateColumns(data, linkType, dataResult): Array<any> {
    let dataMap = data.data.map(x => {
        let displayTax;
        let displayCodePrefix;
        let buttonsToDisplay;
        let displayDescription;
        let displayCode;
        switch (x.name_tax) {
            case undefined:
                displayTax = false;
                break;
            case null:
                displayTax = false;
                break;
            default:
                displayTax = x.name_tax
                break;
        }
        switch (x.code_prefix) {
            case "USD":
                displayCodePrefix = "";
                break;
            default:
                if (x.code_prefix == undefined || x.code_prefix == null) displayCodePrefix = " ";
                else displayCodePrefix = x.code_prefix;
                break;
        }
        if (x.link_webpay == "" && x.link_linkify == "" && x.link_whmcs == "") {
            linkType = "none";
            buttonsToDisplay = {
                buttonPaymentOthers: {
                    href: "",
                    id: 'BOTON-ORDEN-' + x.invoiceid
                }
            }
        } else if (x.link_linkify != "" && x.link_webpay != "" && x.link_whmcs == "") {
            linkType = "both";
            buttonsToDisplay = {
                buttonDebit: {
                    href: x.link_webpay,
                    id: 'BOTON-PAGO-DEBITO-' + x.invoiceid
                },
                buttonTransfer: {
                    href: x.link_linkify,
                    id: 'BOTON-PAGO-TRANSF-' + x.invoiceid
                }
            }
        } else if (x.link_linkify == "" && x.link_webpay == "" && x.link_whmcs != "") {
            linkType = "single";
            buttonsToDisplay = {
                buttonPaymentOthers: {
                    href: x.link_whmcs,
                    id: 'BOTON-ORDEN-' + x.invoiceid
                }
            }
        } else {
            linkType = "none";
            buttonsToDisplay = {
                buttonPaymentOthers: {
                    href: "",
                    id: 'BOTON-ORDEN-' + x.invoiceid
                }
            }
        }
        if (x.description == undefined || x.description == null) displayDescription = " ";
        else displayDescription = x.description;
        if (x.invoiceid == undefined || x.invoiceid == null) displayCode = " ";
        else displayCode = x.invoiceid;
        return {
            columns: [
                {
                    text: displayDescription
                },
                {
                    code: displayCode
                },
                {
                    value: displayCodePrefix + " " + x.prefix + x.amount,
                    tax: displayTax
                },
                buttonsToDisplay
            ]
        }
    });
    dataResult.body = dataMap;
    return [data, linkType, dataResult];
}