<section class="sectionSearchResult">
  <div class="sectionSearchResult__container">

    <h2 i18n>Resultados de tu búsqueda.</h2>

    <!-- START searchResult -->
    <table class="searchResult" aria-describedby="resultTable" *ngIf="!showAlertMessage">

      <thead class="searchResult__thead">
        <tr>
          <ng-container *ngFor="let col of dataResult.header; let i = index">
            <th class="searchResult__th">
              <b i18n>{{col}}</b>
            </th>
          </ng-container>
        </tr>
      </thead>

      <tbody class="searchResult__tbody" >
        <tr class="searchResult__tr" *ngFor="let row of dataResult.body">

          <td class="searchResult__td" *ngFor="let col of row.columns; let i = index">
            <b class="title" *ngIf="dataResult.header[i].length > 0" i18n>{{dataResult.header[i]}}</b>
            <ng-container *ngIf="col.text">
              <div class="text" i18n [innerHTML]="col.text"></div>
            </ng-container>
            <span *ngIf="col.code">#{{col.code}}</span>
            <span *ngIf="col.value">
              <b class="value">{{col.value}}</b> <br>
            </span>
            <span *ngIf="col.tax" i18n>{{col.tax}} incluido</span>
            <ng-container class="flex_column" [ngSwitch]="linkType">
              <div *ngSwitchCase="'both'">
                  <div class="flex_buttons">
                    <a
                      [href]="col.buttonDebit.href"
                      target="_blank"
                      class="radius button"
                      *ngIf="col.buttonDebit" i18n>
                      Débito o Crédito</a>
                    <a
                    [href]="col.buttonTransfer.href"
                    target="_blank"
                    class="radius button"
                    *ngIf="col.buttonTransfer" i18n>
                    Transferencia</a>
                  </div>
              </div>
              <div *ngSwitchCase="'single'">
                <a
                [href]="col.buttonPaymentOthers.href"
                target="_blank"
                class="radius button"
                *ngIf="col.buttonPaymentOthers" i18n>Ver Orden</a>
              </div>
              <div *ngSwitchCase="'none'">
                <p *ngIf="col.buttonPaymentOthers || col.buttonDebit || col.buttonTransfer">Lo sentimos, no se ha encontrado un link de pago</p>
              </div>    
            </ng-container>  
          </td>
        </tr>
      </tbody>
    </table>
    <!-- END searchResult -->

    <!-- START notification -->
    <div class="notification" *ngIf="showAlertMessage">
      <svg-icon-sprite
        [src]="'icon-sprite-comment-exclamation'"
        [width]="'41px'">
      </svg-icon-sprite>
      <p i18n>
        Lo sentimos! sólo encontramos ordenes impagas de servicios que ya están terminados, si gustas puedes contratar un nuevo producto o servicio.
      </p>
    </div>
    <!-- END notification -->
  </div>
</section>
