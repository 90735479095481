<!--  CONTACT CONTENT  -->
<div class="contact-section">
  <div class="row">
    <div class="small-12 large-4 medium-4 columns">
      <p i18n>En Hosty, nos importa tu opinión. Te invitamos a que compartas cualquier duda, consulta o comentario con
        nosotros. Tenemos ejecutivos disponibles para darte toda la ayuda que necesites.</p>

      <div class="contact-details">
        <h4>Hosty</h4>
        <ul>
          <li><a [routerLink]="['/contacto']">venta@hosty.{{tld}}</a></li>
        </ul>
      </div>
    </div>
    <div class="small-12 large-8 medium-8 columns">

      <div id="contactform">
        <form [formGroup]="form">
          <div class="sendstatus" *ngIf="!form.valid">
            <div class="alert-box alert" *ngIf="submitted && !form.get('name').valid">Please enter your name</div>
            <div class="alert-box alert" *ngIf="submitted && !form.get('email').valid">Please enter a valid email address</div>
            <div class="alert-box alert" *ngIf="submitted && !form.get('comments').valid">Please leave a comment.</div>
          </div>
          <div class="sendstatus" *ngIf="form.valid && isNameValid">
            <div class="alert-box success" *ngIf="submitted">
              Thanks {{userName}}. Your message has been sent.
              <a class="close" (click)="refresh()">×</a>
            </div>
          </div>

          <p>
            <label for="name" i18n>Nombre:*</label>
            <input type="text" name="name" id="name" tabindex="1" formControlName="name" required>
          </p>
          <p>
            <label for="email" i18n>Email:*</label>
            <input type="text" name="email" id="email" tabindex="2" formControlName="email" required>
          </p>
          <p>
            <label for="comments" i18n>Mensaje:*</label>
            <textarea name="comments" id="comments" cols="12" rows="6" tabindex="3" formControlName="comments" required></textarea>
          </p>
          <p><input type="button" name="submit" id="submit" class="button radius medium" value="Submit" (click)="onSubmit()"></p>

        </form>
      </div>

    </div>
  </div>
</div>
<!--  END OF CONTACT CONTENT  -->

<!--  payment TIMELINE  -->
<div class="payment-subheader" [preRender]="false" (deferLoad)="showImage1 = true">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>ESTAMOS PARA AYUDARTE</h2>
      <p i18n>Porque nos interesa brindar un buen servicio y darte una mano en lo que necesites, tenemos variadas formas de comunicarnos.</p>

      <div class="spacing-top-50"></div>

      <!-- 1st ELEMENT  -->
      <div class="row collapse supportchannels" *ngFor="let item of features; let odd = odd">
        <div class="small-12 large-4 large-offset-1 medium-4 medium-offset-1 columns">

          <div data-wow-delay="0.3s" class="timeline-content wow fadeInLeft">

            <ng-container *ngIf="!odd; else even">
              <h3 i18n>{{item.title}}</h3>
              <p i18n>
                {{item.text}}
                <span *ngIf="item.id === 3">{{currentPhone}}</span>
                <span *ngIf="item.id === 1">soporte@hosty.{{tld}} .</span>
              </p>
              <a [href]="item.hasOwnProperty('link') ? item.link : 'javascript:void(0)'" [ngClass]="item.class_link" i18n>{{item.text_link}}</a>
            </ng-container>

            <ng-template #even>
              <ng-container *ngIf="showImage1"><!-- showImage1 because we need render before. -->
                <img src="/assets/images/contact/{{item.img}}.png" alt="" class="imgpaddingright">
              </ng-container>
            </ng-template>
          </div>

        </div>

        <div class="large-1 large-offset-1 medium-1 medium-offset-1 hide-for-small line columns">
          <div class="roundimg wow fadeInUp">
            <ng-container *ngIf="showImage1"><!-- showImage1 because we need render before. -->
              <img src="/assets/images/contact/support_icon_1.png" alt="icon support">
            </ng-container>
          </div>
        </div>

        <div class="small-12 large-4 large-offset-1 medium-4 medium-offset-1 columns">
          <div data-wow-delay="0.3s" class="timeline-content wow fadeInRight">
            <ng-template #even>
              <ng-container *ngIf="showImage1"><!-- showImage1 because we need render before. -->
                <img src="/assets/images/contact/{{item.img}}.png" alt="" class="imgpaddingright">
              </ng-container>
            </ng-template>

            <ng-container *ngIf="odd; else even">
              <h3 i18n>{{item.title}}</h3>
              <p i18n>{{item.text}}</p>
              <a [href]="item.hasOwnProperty('link') ? item.link : 'javascript:void(0)'" [ngClass]="item.class_link" i18n>{{item.text_link}}</a>
            </ng-container>
          </div>
        </div>

      </div>
      <!-- END OF 1st ELEMENT  -->

    </div>
  </div>
</div>
<!-- END OF payment TIMELINE  -->
