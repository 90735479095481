import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { URL } from '../../environments/environment';
import { LocaleService } from './locale.service';


@Injectable()
export class DomainService {
    public URL = URL
    public doc$ = new Subject<string>();
    public domain$ = new Subject<string>();
    aApiURLPrices = URL.HOSTY_API_FRONTEND+'getdomain.php';
    CurrenciCode;
    tlds;
    private domain: string;

    constructor(
        private http: HttpClient,
        private localeService: LocaleService
    ) {
        this.CurrenciCode = this.localeService.content.currencyCode;
        this.tlds = this.localeService.content.tdls
    }

    public getValue$(): Observable<string> {
        return this.doc$.asObservable();
    }

    public setValue(doc: string): void {
        this.doc$.next(doc);
    }

    public setdomain(searchdomain: string) {
        this.domain = searchdomain;
        this.domain$.next(searchdomain);
    }

    public getCurrencyDomain$(): Observable<string>  {
        return this.domain$.asObservable();
    }

    public getDomains(): Observable<any> {
        const body = new HttpParams()
        .set('domain', this.domain)
        .set('currency', this.CurrenciCode)
        .set('tlds', this.tlds.toString());

        return this.http.post(this.aApiURLPrices, body);
    }
}
