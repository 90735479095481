<!-- INTRO TERMINOS Y CONDICIONES   -->
<div class="features servers">
  <div class="row">
    <div class="small-12 columns">
      <h2 i18n>NUESTRAS FORMAS DE PAGO</h2>
      <p i18n>Elige la forma de pago que más se acomode a lo que necesitas.</p>
    </div>
  </div>
</div>
<!--  MAIN PAGE CONTENT  -->
<div id="faq-content">
  <div class="faq-subheader">
    <div class="row">
      <div class="small-12 columns">

        <!--  CATEGORY FAQ  -->
        <div class="faqcategory" *ngFor="let payment of payments">
          <div class="row">
            <div class="small-12 large-3 medium-3 columns">
              <h3 class="title1" i18n>{{payment.title}}</h3>
            </div>
            <div class="small-12 large-9 medium-9 columns spacing-top-15">

              <ng-container *ngFor="let item of payment.info; let i = index">

                <p i18n><ng-container *ngIf="payment.info.length > 1">{{i + 1}}.</ng-container> {{item.title}}<br>
                  <strong>(*) Los Servidores Dedicados y VPS tiene otro tiempo de activación, consulte con el equipo de Ventas</strong>
                  <ng-container *ngIf="item.hasOwnProperty('text')">
                    <br><br>{{item.text}}
                  </ng-container>
                </p>

                <ul class="small-block-grid-2" [ngClass]="item.class">
                  <li *ngFor="let img of item.images">
                    <img *ngIf="locale != 'es-CO' && locale != 'es-MX' && locale != 'es-AR'"
                      src="/assets/images/payment-methods/{{img}}.svg" class="circle" width="100px" alt="{{img}}">
                    <img *ngIf="locale == 'es-CO' || locale == 'es-MX' || locale == 'es-AR'"
                     src="/assets/images/payment-methods/{{img}}" class="circle" width="100px" alt="">
                  </li>
                </ul>

                <ng-container *ngIf="payment.title === 'TRANSFERENCIA O DEPÓSITO BANCARIO' && locale == 'es-CL'" >
                  <p i18n>Banco Itaú<br>
                    Titular: Hosty SpA<br>
                    N° de Cuenta Corriente: 208999928<br>
                    RUT: 76.430.498-5</p>
                </ng-container>

                <ng-container *ngIf="payment.title === 'TRANSFERENCIA O DEPÓSITO BANCARIO' && locale == 'es-PE'">

                <ul class="small-block-grid-2" [ngClass]="item.class">
                    <li>
                      <img src="/assets/images/payment-methods/logo-bcp.svg" class="circle" width="100px" alt="">
                    </li>
                  </ul>

                    <p> <strong>Banco BCP </strong><br>
                      Titular: PagoMaster S.A.C. <br>
                      N° de Cuenta Corriente Soles S/.: 194-2157025-0-30<br>
                      N° de Cuenta Corriente Dólares USD: 194-2171047-1-76<br>
                      RUC: 20565999754  <br>
                     pago@hosty.pe</p>
                  </ng-container>

                <ng-container *ngIf="payment.title === 'TRANSFERENCIA O DEPÓSITO BANCARIO' && locale == 'es-PE'">

                <ul class="small-block-grid-2" [ngClass]="item.class">
                    <li>
                      <img src="/assets/images/payment-methods/logo-interbank.svg" class="circle" width="100px" alt="">
                    </li>
                  </ul>
                    <p> <strong>Banco Interbank </strong><br>
                      Titular: PagoMaster S.A.C. <br>
                      N° de Cuenta Corriente Soles S/.: 107-300105942-0<br>
                      N° de CCI S/.: 003-107-003001059420-05<br>
                      <br>
                      <br>
                      N° de Cuenta Corriente Dólares USD: 107-300105943-8 <br>
                      N° de CCI Dólares USD: 003-107-003001059438-07 <br>
                      RUC: 20565999754 <br>
                    pago@hosty.pe</p>
                  </ng-container>

              </ng-container>

            </div>
          </div>
        </div>
        <!--  CATEGORY FAQ  END -->

      </div>
    </div>
  </div>
</div>
