export const ArrHeader = {
    default: {
        class: 'home',
        title1: 'Planes Web Hosting Ecónomicos',
        message_text: [
            'UPTIME 99%',
            'SOPORTE TÉCNICO',
            'QUE DESPEGUEN SUS PLANES',
            'CON NUESTRAS PROMOCIONES',
            'MIGRACIÓN GRATUITA'
        ],
        has_line: false
    },
    web_hosting: {
        class: 'alt-1',
        title1: 'Planes Económico',
        message_text: 'PLANES DE HOSTING ECONÓMICOS.',
        has_line: false
    },
    reseller: {
        class: 'alt-7',
        title1: 'Planes Reseller',
        message_text: 'COMIENCE SU PROPIO NEGOCIO',
        has_line: false
    },
    pyme: {
        class: 'alt-6',
        title1: 'Planes PYME SSD',
        message_text: 'SERVICIO MÁS ESPECIALIZADO',
        has_line: false
    },
    vps: {
        class: 'alt-2',
        title1: 'Planes VPS',
        message_text: 'CONTROL AL 100%',
        has_line: false
    },
    vps_backup: {
        class: 'alt-2',
        title1: 'Planes VPS + BACKUP',
        message_text: 'CONTROL AL 100%',
        has_line: false
    },
    programa_auspicio: {
        class: 'alt-1',
        title1: 'Programa de Auspicio',
        message_text: 'AYUDÉMOSNOS MUTUAMENTE',
        has_line: true
    },
    programa_afiliados: {
        class: 'alt-7',
        title1: 'Programa de Afiliados',
        message_text: 'COMPARTA Y GANE',
        has_line: true
    },
    formas_de_pago: {
        class: 'alt-3',
        title1: 'Formas de Pago',
        message_text: 'MUCHAS OPCIONES PARA USTED',
        has_line: true
    },
    centro_de_logotipos: {
        class: 'alt-3',
        title1: 'Centro de Logotipos',
        message_text: 'NUESTROS BANNERS',
        has_line: true
    },
    blog: {
        class: 'alt-2',
        title1: 'blog',
        message_text: 'NUESTRAS ÚLTIMAS NOVEDADES',
        has_line: true
    },
    contacto: {
        class: 'alt-1',
        title1: 'Contacto & Soporte',
        message_text: '¿EN QUÉ PODEMOS AYUDARLE?',
        has_line: true
    },
    dominios: {
        class: 'alt-4',
        title1: 'Dominios',
        message_text: 'REGISTRE SU SITIO.',
        has_line: true
    },
    promo: {
        class: 'alt-8',
        title1: '¡PROMO HOSTY!',
        message_text: 'PLANES DE HOSTING ECONÓMICOS',
        has_line: false
    },
    direct_payment: {
        class: 'direct-payment',
        title1: '',
        message_text: 'PAGA TU DEUDA AL INSTANTE.',
        has_line: false
    }
};

export const DATA_SITE_CL = {
    phone: '+ 56 2 2573 9464',
    tel: '+56225739464',
    apply_now_link: 'https://panel.hosty.host/cart.php?a=add&pid=1&currency=1&tld=.cl',
    buy_now_link: 'https://panel.hosty.host/cart.php?a=add&pid=2&currency=1&tld=.cl&promocode=hosty30'
};

export const DATA_SITE_PE = {
    phone: '+51 1 709 4317',
    tel: '+5117094317',
    apply_now_link: 'https://panel.hosty.host/cart.php?a=add&pid=1&currency=3&tld=.pe',
    buy_now_link: 'https://panel.hosty.host/cart.php?a=add&pid=2&currency=3&tld=.pe&promocode=hosty30'
};

export const DATA_SITE_CO = {
    phone: '+57 601 381 9035',
    tel: '+576013819035',
    apply_now_link: 'https://panel.hosty.host/cart.php?a=add&pid=1&currency=6&tld=.co',
    buy_now_link: 'https://panel.hosty.host/cart.php?a=add&pid=2&currency=6&tld=.co&promocode=hosty30'
};

export const DATA_SITE_ME = {
    phone: '+52 55 3548 0102',
    tel: '+56225739464',
    apply_now_link: 'https://panel.hosty.host/cart.php?a=add&pid=1&currency=8&tld=.com.mx',
    buy_now_link: 'https://panel.hosty.host/cart.php?a=add&pid=2&currency=8&tld=.com.mx&promocode=hosty30'
};

export const DATA_SITE_AR = {
    phone: '+54 11 3988 4087',
    tel: '+56225739464',
    apply_now_link: 'https://panel.hosty.host/cart.php?a=add&pid=1&currency=4&tld=.com.ar',
    buy_now_link: 'https://panel.hosty.host/cart.php?a=add&pid=2&currency=4&tld=.com.ar&promocode=hosty30'
};
